import React from "react";
import { Button, Form } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { set } from "lodash";

const CategoryDropDown = ({
  categoriesFilters,
  className,
  setCategoryOpen,
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const subCategories = searchParams.get("sub")?.split(",") ?? [];
  const [subCategoriesList, setSubCategoriesList] =
    React.useState(subCategories);

  const handleSubCategory = (e) => {
    e.stopPropagation();
    if (e?.target?.checked) {
      setSubCategoriesList((prev) => [...prev, e?.target?.name]);
    } else {
      const index = subCategoriesList.indexOf(e?.target?.name);
      if (index > -1) {
        setSubCategoriesList((prev) => prev?.filter((_, i) => i !== index));
      }
    }
  };

  const handleSearchClick = (e) => {
    e.stopPropagation();
    const newSearchParams = new URLSearchParams(searchParams);
    if (subCategoriesList?.length > 0) {
      newSearchParams.set("sub", subCategoriesList.join(","));
    } else {
      newSearchParams.delete("sub");
    }
    setSearchParams(newSearchParams);
    setCategoryOpen((prev) => !prev);
  };

  return (
    <div className={`filterDropdown modal_bottom ${className}`}>
      <span
        className="d-block d-md-none overlay"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setCategoryOpen((prev) => !prev);
        }}
      ></span>
      <div className="holder">
        <div className="mb-4 d-flex align-items-center justify-content-between d-md-none">
          <h1 className="fw-700 font-bukra text-black title-lg m-0">
            {t("Sub Categories")}
          </h1>

          <Button
            className="closeBtn primary-color text-decoration-none fw-700"
            variant="link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setCategoryOpen((prev) => !prev);
            }}
          >
            {t("Close")}
          </Button>
        </div>
        {categoriesFilters?.filters?.map((subCategory) => (
          <div
            className="d-flex align-items-start justify-content-between item"
            key={subCategory?.id}
          >
            <Form.Check
              type="checkbox"
              label={subCategory?.name}
              name={subCategory?.id}
              value={subCategory?.id}
              checked={subCategoriesList?.includes(subCategory?.id?.toString())}
              className="custom-checkbox add d-flex align-items-start"
              onChange={handleSubCategory}
            />
            <span className="count primary-color">
              {subCategory?.offer_count}
            </span>
          </div>
        ))}

        <div className="btn-wrap">
          <Button
            variant="primary"
            className="w-100"
            aria-label="show result button"
            disabled={subCategoriesList?.length === 0}
            onClick={handleSearchClick}
          >
            {t("Show Results")}
          </Button>
          <Button
            variant="outline"
            className="w-100"
            aria-label="clear all filter button"
            onClick={(e) => {
              searchParams.delete("sub");
              e.preventDefault();
              e.stopPropagation();
              setSearchParams(searchParams);
              setCategoryOpen((prev) => !prev);
            }}
            disabled={subCategoriesList?.length === 0}
          >
            {t("Clear Filter")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CategoryDropDown;
