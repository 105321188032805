import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLangState } from "../../redux/configurationSlice";
import { CategoryAPI } from "../../apis/categoryApi";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const CategoriesDropdown = ({ showDropdown, handleDropdown }) => {
  const { t } = useTranslation();
  const [showInnerDropdown, setShowInnerDropdown] = useState(null);
  const navigate = useNavigate();
  const { lang } = useSelector(selectLangState);
  const { data } = useQuery({
    queryKey: [
      "/web/categories",
      {
        company: "ESD",
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "WEB",
      },
    ],
    queryFn: ({ queryKey }) => CategoryAPI.getCategories({ ...queryKey[1] }),
  });

  const handleInnerDropdown = (key, value) => {
    setShowInnerDropdown({ [key]: value ?? !showInnerDropdown?.[key] });
  };

  const getActiveClass = (currentValue) => {
    if (!isEmpty(showInnerDropdown)) {
      const activeValues = Object.keys(showInnerDropdown);
      if (activeValues?.length) {
        let activeValue = activeValues[0];
        if (currentValue === activeValue) return "active";
      }
      return "";
    }
  };

  useEffect(() => {
    if (data?.categories?.length) {
      const firstCategory = data?.categories[0];
      const key = firstCategory?.category_filter_val;
      handleInnerDropdown(key, true);
    }
  }, [data]);

  return (
    <div
      className={`dropdown-menu menu_category ${showDropdown ? "show" : ""}`}
      id="Category-dd"
      onMouseLeave={() => handleDropdown(false)}
      onMouseEnter={() => handleDropdown(true)}
    >
      <ul className="menu_categoryItems mb-2">
        {data?.categories?.map((category) => (
          <li
            className={getActiveClass(category?.category_filter_val)}
            key={category?.category_id}
            onClick={() => {
              handleDropdown(false);
              navigate(
                `/categories/${category?.category_filter_val}?sort=nearest`
              );
            }}
          >
            <Link
              className="dropdown-item d-flex align-items-center"
              onMouseOver={() =>
                handleInnerDropdown(category?.category_filter_val, true)
              }
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <Image
                src={category?.category_icon || "/images/placeholderImage.png"}
                alt="icon"
                height={25}
                width={25}
                className="me-2"
              />
              <span className="cat_title overflow-hidden text-truncate">
                {category?.category_name}
              </span>
            </Link>
            <span
              className="ico-right d-xl-none"
              onClick={(e) => {
                e.stopPropagation();
                handleInnerDropdown(category?.category_filter_val);
              }}
            ></span>
            {getActiveClass(category?.category_filter_val) &&
              showInnerDropdown?.[category?.category_filter_val] && (
                <div className="subCat_Menu">
                  <h6 className="mb-3">{category?.category_name}</h6>
                  <Row>
                    <Col md={6}>
                      <ul
                        className="subCat_Items d-flex flex-column flex-wrap"
                        style={{ maxHeight: "400px", columnGap: "25px" }}
                      >
                        {category?.sub_categories?.map((sub) => (
                          <li
                            key={sub?.sub_category_id}
                            style={{ width: "200px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                `/categories/${category?.category_filter_val}?sub=${sub?.sub_category_id}&sort=nearest`
                              );
                            }}
                          >
                            <Link
                              className="item d-flex align-items-center justify-content-between"
                              data-bs-toggle="collapse"
                              data-bs-target="#navbarNavDropdown"
                              aria-controls="navbarNavDropdown"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                            >
                              <span className="title">
                                {sub?.sub_category_name}
                              </span>
                              <span className="count">{sub?.offer_count}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                </div>
              )}
          </li>
        ))}
      </ul>
      <div className="px-3">
        <Button
          variant="outline"
          className="w-100 fw-500 py-1"
          onClick={() => {
            navigate("/categories");
            handleDropdown(false);
          }}
        >
          {t("Show All")}
        </Button>
      </div>
    </div>
  );
};

export default CategoriesDropdown;
