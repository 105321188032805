import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";

const CardTile = ({
  to,
  imageUrl,
  offerTitle,
  validity,
  offerType,
  discount,
  distance,
  offer,
  tags = [],
  rating,
  valid_till,
}) => {
  const { t } = useTranslation();
  return (
    <div className="src-Card d-flex">
      <div className="imgHolder">
        <Link to={to}>
          <Image
            src={imageUrl}
            alt="image"
            className="img-fluid d-block w-100 h-100 object-fit-cover"
          />
        </Link>
      </div>
      <div className="text">
        <div className="head d-flex align-items-start">
          <h3 className="font-bukra">
            <Link to={to} className="primary-color text-decoration-none">
              {offerTitle}
            </Link>
          </h3>
          {tags?.length > 0 && (
            <Stack direction="horizontal" gap={2} className="ms-auto ps-2">
              {tags?.map((tag, key) => (
                <Badge
                  pill
                  key={key}
                  bg={tag?.text === "Featured" ? "light" : "primary"}
                  text={tag?.text === "Featured" ? "dark" : "white"}
                  className={tag?.text !== "Featured" && "dir-ltr"}
                >
                  {tag?.text}
                </Badge>
              ))}
            </Stack>
          )}
          {/* <Stack direction="horizontal" gap={2} className="ms-auto ps-2">
            <Badge pill bg="primary">
              {discount}% OFF
            </Badge>
            <Badge pill bg="light" text="dark">
              {offerType}
            </Badge>
          </Stack> */}
        </div>
        <p className="font-bukra d-flex">
          <span className="dir-ltr">{valid_till}</span>
        </p>
        <div className="d-flex align-items-center">
          <div className="rating d-flex align-items-center me-1">
            <Image src="/images/Star.svg" alt="Star" className="me-1" />
            <span className="font-bukra fw-700">{rating}</span>
          </div>
          <Stack direction="horizontal" gap={2} className="ps-2">
            <Badge pill bg="light" text="dark" className="">
              {t("{{distance}} km", { distance: distance })}
            </Badge>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default CardTile;
