import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";

const RedemptionCard = ({
  to,
  imageUrl,
  offerTitle,
  validity,
  discount,
  refCode,
  amountTitle,
  amount = 0,
  address,
}) => {
  const { t } = useTranslation();

  return (
    <div className="src-Card hrItem add">
      <div className="d-flex">
        <div className="imgHolder me-3 rounded overflow-hidden">
          {/* <Link to={to}> */}
          <Image
            src={imageUrl}
            alt="image"
            className="img-fluid d-block w-100 h-100 object-fit-cover"
          />
          {/* </Link> */}
        </div>
        <div className="text">
          <div className="head d-flex align-items-start justify-content-between ">
            <h3 className="font-bukra">
              <span className="primary-color text-decoration-none">
                {offerTitle}
              </span>
            </h3>
            <Stack
              direction="horizontal"
              className="position-static align-items-start ms-3"
            >
              <Badge pill bg="primary">
                {discount}
              </Badge>
            </Stack>
          </div>
          <p>{address}</p>
          <p className="m-0 d-flex"><span className="dir-ltr">{validity}</span></p>
        </div>
      </div>
      <div className="rdm-ItemInfo">
        <div className="column code d-inline-flex align-items-center">
          <span className="label">{t("Ref-code")}</span>
          <span className="num fw-700">{refCode}</span>
          <CopyToClipboard
            text={refCode}
            onCopy={() => toast.success(t("Copied"))}
          >
            <span className="link primary-color fw-500">{t("Copy")}</span>
          </CopyToClipboard>
        </div>
        <div className="column price">
          <span className="label">{amountTitle}</span>
          <span className="num fw-700">
            {t("AED")} {amount}
          </span>
        </div>
      </div>
    </div>
  );
};

export default RedemptionCard;
