import { useCallback, useEffect, useState } from "react";
import PageBanner from "../Components/Global/PageBanner";
import { Col, Container, Row, Button, Image } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import InputField from "../Components/Form/InputField";
import InputLabel from "../Components/Form/InputLabel";
import FileUpload from "../Components/Form/FileUpload";
import ListGroup from "react-bootstrap/ListGroup";
import BranchTab from "../Components/Global/BranchTab";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { Controller, useFieldArray } from "react-hook-form";
import FieldValidationText from "../Components/Global/fieldValidationText";
import BranchForm from "../Components/Form/BranchForm";
import { defaultBranchValues, defaultCompanyValues } from "../constants";
import { selectLangState } from "../redux/configurationSlice";
import { useSelector } from "react-redux";
import { HomeAPI } from "../apis/homeApi";
import { useQuery } from "@tanstack/react-query";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import {
  ARABIC_NUMERIC_SEPECIAL_REGEX,
  ENGLISH_NUMERIC_SPECIAL_REGEX,
  FormField,
  GENERAL_URL_REGEX,
  PHONE_REGEX,
} from "../utils/constants";
import ButtonWithLoading from "../Components/buttons/ButtonWithLoading";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import PhoneNumberField from "../Components/Global/phoneInput";
import useForm from "../hooks/useForm";
import { phoneNumberForDB } from "../utils/utilityFunctions";

const RegisterCompany = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [branch, setBranch] = useState(false);
  const [branchState, setBranchState] = useState(defaultBranchValues);
  const { lang } = useSelector(selectLangState);
  const { data: configuration } = useQuery({
    queryKey: [
      "config",
      {
        company_code: "ESD",
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getConfig({ ...queryKey[1] }),
  });
  const [emirateOptions, setEmirateOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    handleChange,
    setValue,
    clearErrors,
    control,
    reset,
    trigger,
    watch,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm({ defaultValues: defaultCompanyValues, mode: "all" });
  const {
    fields: branches,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "branches",
    rules: {
      validate: (value) => {
        if (value.length === 0 && watch("hasBranches")) {
          return t(
            "Please provide details about one of your outlets, or unslecet the outlet option to proceed and compelte the registration."
          );
        }
      },
    },
  });

  const handleSuccessClose = () => {
    reset();
    navigate("/home");
    setShow(false);
    window.scrollTo(0, 0);
  };

  const handelNextTab = async (e) => {
    e.preventDefault();
    let validationArr = [];
    if (activeTab === 0) {
      validationArr = FormField.firstForm;
    }
    if (activeTab === 1) {
      validationArr = FormField.secondForm;
    }
    const isValid = await trigger(validationArr);
    if (!isValid) {
      return;
    }
    if (activeTab === 2) {
      return;
    }
    setActiveTab((prev) => prev + 1);
  };

  const handelBackTab = () => {
    if (activeTab === 0) {
      return;
    }
    setActiveTab((prev) => prev - 1);
  };

  const handleClose = () => setBranch(false);
  const handleShow = () => {
    setBranchState(defaultBranchValues);
    setBranch(true);
  };

  const onSubmit = useCallback(
    async (values) => {
      try {
        setIsLoading(true);
        if (!executeRecaptcha) {
          toast.error(t("Captcha missing"));
          setIsLoading(false);
          return;
        }
        const result = await executeRecaptcha("register");
        const formData = new FormData();
        formData.append("language", lang?.startsWith("ar") ? "ar" : "en");
        formData.append("merchant_name_en", values?.companyNameEn);
        formData.append("merchant_name_ar", values?.companyNameAr);
        formData.append("merchant_description_en", values?.descriptionEn);
        formData.append("merchant_description_ar", values?.descriptionAr);
        formData.append("merchant_category", values?.category?.value);
        formData.append("merchant_address_en", values?.addressEn);
        formData.append("merchant_address_ar", values?.addressAr);
        formData.append("merchant_email", values?.email);
        if (values?.landlineNumber) {
          formData.append(
            "merchant_landline_no",
            phoneNumberForDB(values?.landlineNumber)
          );
        }
        formData.append("merchant_mobile_no", phoneNumberForDB(values?.mobile));
        formData.append("merchant_owner_en", values?.ownerNameEn);
        formData.append("merchant_owner_ar", values?.ownerNameAr);
        if (values?.websiteURL) {
          formData.append("website", values?.websiteURL);
        }
        if (values?.facebookURL) {
          formData.append("facebook", values?.facebookURL);
        }
        if (values?.instagramURL) {
          formData.append("instagram", values?.instagramURL);
        }
        if (values?.xURL) {
          formData.append("twitter_x", values?.xURL);
        }
        formData.append("country", values?.country?.value);
        formData.append("emirate", values?.emirate?.value);
        formData.append("has_outlets", values?.hasBranches);
        if (values?.hasBranches) {
          values?.branches?.forEach((branch) => {
            const { id, ...rest } = branch;
            let newBranch = {
              outlet_name_en: rest?.nameEn,
              outlet_name_ar: rest?.nameAr,
              outlet_address_en: rest?.addressEn,
              outlet_address_ar: rest?.addressAr,
              outlet_lat: Number(rest?.latitude),
              outlet_lng: Number(rest?.longitude),
              outlet_country: rest?.country?.value,
              // outlet_emirate: rest?.emirate?.value,
              outlet_city: rest?.city?.value,
              outlet_is_primary: rest?.isPrimary ?? false,
            };
            if (rest?.mall?.value) {
              newBranch = {
                ...newBranch,
                outlet_mall: rest?.mall?.value,
              };
            }
            if (rest?.mobile) {
              newBranch = {
                ...newBranch,
                outlet_mobile_no: phoneNumberForDB(rest?.mobile),
              };
            }
            if (rest?.landlineNumber) {
              newBranch = {
                ...newBranch,
                outlet_landline_no: phoneNumberForDB(rest?.landlineNumber),
              };
            }
            if (rest?.managerNameEn) {
              newBranch = {
                ...newBranch,
                outlet_manager_name_en: rest?.managerNameEn,
              };
            }
            if (rest?.managerNameAr) {
              newBranch = {
                ...newBranch,
                outlet_manager_name_ar: rest?.managerNameAr,
              };
            }
            if (rest?.managerMobile) {
              newBranch = {
                ...newBranch,
                outlet_manager_mobile_no: phoneNumberForDB(rest?.managerMobile),
              };
            }
            if (rest?.managerEmail) {
              newBranch = {
                ...newBranch,
                outlet_manager_email: rest?.managerEmail,
              };
            }
            formData.append("outlets", JSON.stringify(newBranch));
          });
        }

        formData.append("merchant_logo", values?.image);
        formData.append("merchant_passport", values?.passport);
        formData.append("merchant_license", values?.license);
        formData.append("captcha_token", result);

        const response = await HomeAPI.registerCompany(formData);
        setShow(true);
        setIsLoading(false);
      } catch (error) {
        toast.error(error?.message);
        setIsLoading(false);
      }
    },
    [executeRecaptcha, t, navigate, reset, lang]
  );
  const handleCancel = () => {
    setActiveTab(0);
    reset();
  };
  const handelBranchAdd = (values) => {
    if (values?.isEdit) {
      const newBranches = branches?.map((branch) => {
        if (branch?.id === values?.id) {
          const { isEdit, ...rest } = values;
          return rest;
        }
        return branch;
      });
      setValue("branches", newBranches);
    } else {
      const { isEdit, ...rest } = values;
      append(rest);
    }
    clearErrors("branches");
    setBranch(false);
  };

  const removeBranch = (index) => {
    remove(index);
  };

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }
    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha("register");
      setToken(token);
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const handleStepBack = (tab) => {
    if (activeTab <= tab) {
      return;
    }
    setActiveTab(tab);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  return (
    <>
      <PageBanner
        imageUrl="/images/bg-pageBnr.png"
        title={`${t("Register Company")}`}
      />
      <div className="contentHolder registerCompany">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="0"
          activeKey={activeTab}
          onSelect={() => {}}
        >
          <Container>
            <Row className="d-flex align-items-center row-wrap">
              <Col xs={12} className="d-flex justify-content-center ">
                <Nav variant="pills" className="sept-tabs">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="0"
                      className={activeTab > 0 ? "pointer" : "cursor-default"}
                      onClick={() => handleStepBack(0)}
                      disabled={activeTab === 0}
                    >
                      <span
                        className={`num d-flex align-items-center justify-content-center rounded-circle mx-auto ${
                          activeTab > 0 ? "bg-primary" : ""
                        }`}
                      >
                        {activeTab > 0 ? (
                          <svg
                            width="24"
                            height="18"
                            viewBox="0 0 24 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.74919 17.6626C7.06793 17.6629 6.41457 17.3921 5.93325 16.91L0.443061 11.4219C-0.147687 10.8309 -0.147687 9.87302 0.443061 9.28209C1.034 8.69134 1.99191 8.69134 2.58284 9.28209L7.74919 14.4484L21.4172 0.780463C22.0081 0.189715 22.966 0.189715 23.5569 0.780463C24.1477 1.3714 24.1477 2.32931 23.5569 2.92024L9.56513 16.91C9.08381 17.3921 8.43045 17.6629 7.74919 17.6626Z"
                              fill="white"
                            />
                          </svg>
                        ) : (
                          1
                        )}
                      </span>

                      {t("Company Information")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="1"
                      className={activeTab > 1 ? "pointer" : "cursor-default"}
                      onClick={() => handleStepBack(1)}
                      disabled={activeTab <= 1}
                    >
                      <span
                        className={`num d-flex align-items-center justify-content-center rounded-circle mx-auto ${
                          activeTab > 1 ? "bg-primary" : ""
                        }`}
                      >
                        {activeTab > 1 ? (
                          <svg
                            width="24"
                            height="18"
                            viewBox="0 0 24 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.74919 17.6626C7.06793 17.6629 6.41457 17.3921 5.93325 16.91L0.443061 11.4219C-0.147687 10.8309 -0.147687 9.87302 0.443061 9.28209C1.034 8.69134 1.99191 8.69134 2.58284 9.28209L7.74919 14.4484L21.4172 0.780463C22.0081 0.189715 22.966 0.189715 23.5569 0.780463C24.1477 1.3714 24.1477 2.32931 23.5569 2.92024L9.56513 16.91C9.08381 17.3921 8.43045 17.6629 7.74919 17.6626Z"
                              fill="white"
                            />
                          </svg>
                        ) : (
                          2
                        )}
                      </span>
                      {t("Upload Documents")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2" className="cursor-default">
                      <span className="num d-flex align-items-center justify-content-center rounded-circle mx-auto">
                        3
                      </span>
                      {t("Branches Information")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Form autoComplete="off">
              <Row>
                <Col xs={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="0">
                      <div className="contentBox">
                        <div className="titleHead">
                          <h2 className="font-bukra fw-700 text-black m-0">
                            {t("Company Information")}
                          </h2>
                        </div>
                        <p>{t("companyRegistFillTitle")}</p>
                        <Row>
                          <Col
                            md={6}
                            xs={12}
                            className={`mb-3 ${lang == "ar" ? "order-1" : ""}`}
                          >
                            <Form.Group
                              controlId="companyName-en"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel
                                text={
                                  <>
                                    {t("Company Name")} ({t("English")})
                                    <span className="text-danger">*</span>
                                  </>
                                }
                              />
                              <InputField
                                className="text-en-start"
                                type="text"
                                placeholder=""
                                {...register("companyNameEn", {
                                  required: t("Company Name is required"),
                                })}
                                onChange={handleChange}
                              />
                              {errors.companyNameEn && (
                                <FieldValidationText
                                  errorMessage={errors.companyNameEn?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col
                            md={6}
                            xs={12}
                            className={`mb-3 ${lang == "ar" ? "order-0" : ""}`}
                          >
                            <Form.Group
                              controlId="companyName-ar"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel
                                text={
                                  <>
                                    {t("Company Name")} ({t("Arabic")})
                                    <span className="text-danger">*</span>
                                  </>
                                }
                              />
                              <InputField
                                className="dirArabic"
                                type="text"
                                placeholder=""
                                {...register("companyNameAr", {
                                  required: t("Company Name is required"),
                                })}
                                onChange={handleChange}
                              />
                              {errors.companyNameAr && (
                                <FieldValidationText
                                  errorMessage={errors.companyNameAr?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md={6}
                            xs={12}
                            className={`mb-3 ${lang == "ar" ? "order-1" : ""}`}
                          >
                            <Form.Group
                              controlId="companyDescription-en"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel
                                text={
                                  <>
                                    {t("Company Description")} ({t("English")})
                                    <span className="text-danger">*</span>
                                  </>
                                }
                              />
                              <InputField
                                className="text-en-start"
                                as="textarea"
                                rows={2}
                                maxLength={1000}
                                placeholder=""
                                {...register("descriptionEn", {
                                  required: t(
                                    "Company description is required"
                                  ),
                                })}
                                onChange={handleChange}
                              />
                              {errors.descriptionEn && (
                                <FieldValidationText
                                  errorMessage={errors.descriptionEn?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col
                            md={6}
                            xs={12}
                            className={`mb-3 ${lang == "ar" ? "order-0" : ""}`}
                          >
                            <Form.Group
                              controlId="companyDescription-ar"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel
                                text={
                                  <>
                                    {t("Company Description")} ({t("Arabic")})
                                    <span className="text-danger">*</span>
                                  </>
                                }
                              />
                              <InputField
                                className="dirArabic"
                                as="textarea"
                                rows={2}
                                maxLength={1000}
                                placeholder=""
                                {...register("descriptionAr", {
                                  required: t(
                                    "Company description is required"
                                  ),
                                })}
                                onChange={handleChange}
                              />
                              {errors.descriptionAr && (
                                <FieldValidationText
                                  errorMessage={errors.descriptionAr?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md={6}
                            xs={12}
                            className={`mb-3 ${lang == "ar" ? "order-1" : ""}`}
                          >
                            <Form.Group
                              controlId="address-en"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel
                                text={
                                  <>
                                    {t("Address")} ({t("English")})
                                    <span className="text-danger">*</span>
                                  </>
                                }
                              />
                              <InputField
                                className="text-en-start"
                                as="textarea"
                                rows={2}
                                placeholder=""
                                {...register("addressEn", {
                                  required: t("Address is required"),
                                })}
                                onChange={handleChange}
                              />
                              {errors.addressEn && (
                                <FieldValidationText
                                  errorMessage={errors.addressEn?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col
                            md={6}
                            xs={12}
                            className={`mb-3 ${lang == "ar" ? "order-0" : ""}`}
                          >
                            <Form.Group
                              controlId="address-ar"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel
                                text={
                                  <>
                                    {t("Address")} ({t("Arabic")})
                                    <span className="text-danger">*</span>
                                  </>
                                }
                              />
                              <InputField
                                className="dirArabic"
                                as="textarea"
                                rows={2}
                                placeholder=""
                                {...register("addressAr", {
                                  required: t("Address is required"),
                                })}
                                onChange={handleChange}
                              />
                              {errors.addressAr && (
                                <FieldValidationText
                                  errorMessage={errors.addressAr?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md={6}
                            xs={12}
                            className={`mb-3 ${lang == "ar" ? "order-1" : ""}`}
                          >
                            <Form.Group
                              controlId="ownerName-en"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel
                                text={
                                  <>
                                    {t("Owner Name")} ({t("English")})
                                    <span className="text-danger">*</span>
                                  </>
                                }
                              />
                              <InputField
                                className="text-en-start"
                                type="text"
                                placeholder=""
                                {...register("ownerNameEn", {
                                  required: t("Owner name is required"),
                                })}
                                onChange={handleChange}
                              />
                              {errors.ownerNameEn && (
                                <FieldValidationText
                                  errorMessage={errors.ownerNameEn?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col
                            md={6}
                            xs={12}
                            className={`mb-3 ${lang == "ar" ? "order-0" : ""}`}
                          >
                            <Form.Group
                              controlId="ownerName-ar"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel
                                text={
                                  <>
                                    {t("Owner Name")} ({t("Arabic")})
                                    <span className="text-danger">*</span>
                                  </>
                                }
                              />
                              <InputField
                                className="dirArabic"
                                type="text"
                                placeholder=""
                                {...register("ownerNameAr", {
                                  required: t("Owner name is required"),
                                })}
                                onChange={handleChange}
                              />
                              {errors.ownerNameAr && (
                                <FieldValidationText
                                  errorMessage={errors.ownerNameAr?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} xs={12} className="mb-3">
                            <Form.Group
                              controlId="email"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel
                                text={
                                  <>
                                    {t("Email Address")}
                                    <span className="text-danger">*</span>
                                  </>
                                }
                              />
                              <InputField
                                className="text-en-start alignRight"
                                type="email"
                                placeholder=""
                                {...register("email", {
                                  required: t("Email is required"),
                                  pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: t("Invalid email address"),
                                  },
                                })}
                                onChange={handleChange}
                              />
                              {errors.email && (
                                <FieldValidationText
                                  errorMessage={errors.email?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={6} xs={12} className="mb-3">
                            <Form.Group
                              controlId="mobileNumber"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel
                                text={
                                  <>
                                    {t("Mobile Number")}
                                    <span className="text-danger">*</span>
                                  </>
                                }
                              />
                              <Controller
                                name="mobile"
                                control={control}
                                rules={{
                                  required: t("Mobile is required"),
                                  // validate: (value) => {
                                  //   if (value && !value.match(PHONE_REGEX))
                                  //     return t("Please enter a valid number");
                                  //   return null;
                                  // },
                                }}
                                render={({ field }) => (
                                  <PhoneNumberField
                                    field={field}
                                    trigger={trigger}
                                  />
                                )}
                              />
                              {errors.mobile && (
                                <FieldValidationText
                                  errorMessage={errors.mobile?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={6} xs={12} className="mb-3">
                            <Form.Group
                              controlId="landlineNumber"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel text={`${t("Landline Number")}`} />
                              <Controller
                                name="landlineNumber"
                                control={control}
                                // rules={{
                                //   validate: (value) => {
                                //     if (value && !value.match(PHONE_REGEX))
                                //       return t("Please enter a valid number");
                                //     return null;
                                //   },
                                // }}
                                render={({ field }) => (
                                  <PhoneNumberField
                                    field={field}
                                    trigger={trigger}
                                  />
                                )}
                              />
                              {errors.landlineNumber && (
                                <FieldValidationText
                                  errorMessage={errors.landlineNumber?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={6} xs={12} className="mb-3">
                            <Form.Group
                              controlId="selectCategory"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel
                                text={
                                  <>
                                    {t("Select Category")}
                                    <span className="text-danger">*</span>
                                  </>
                                }
                              />
                              <Controller
                                name="category"
                                control={control}
                                rules={{ required: t("Category is required") }}
                                render={({ field }) => (
                                  <ReactSelect
                                    {...field}
                                    options={
                                      configuration?.categories
                                        ?.map((category) => ({
                                          label: category?.category_name,
                                          value: category?.category_identifier,
                                        }))
                                        ?.sort((a, b) =>
                                          a.label.localeCompare(b.label)
                                        ) ?? []
                                    }
                                    placeholder={t("Select category")}
                                    onChange={field.onChange}
                                    noOptionsMessage={() => t("No options")}
                                  />
                                )}
                              />
                              {errors.category && (
                                <FieldValidationText
                                  errorMessage={errors.category?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={6} xs={12} className="mb-3">
                            <Form.Group
                              controlId="country"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel
                                text={
                                  <>
                                    {t("Country")}
                                    <span className="text-danger">*</span>
                                  </>
                                }
                              />
                              <Controller
                                name="country"
                                control={control}
                                rules={{ required: t("Country is required") }}
                                render={({ field }) => (
                                  <ReactSelect
                                    {...field}
                                    options={
                                      configuration?.countries
                                        ?.map((country) => ({
                                          label: country?.country_name,
                                          value: country?.country_id,
                                        }))
                                        ?.sort((a, b) =>
                                          a.label.localeCompare(b.label)
                                        ) ?? []
                                    }
                                    placeholder={t("Select country")}
                                    onChange={(value) => {
                                      let emirates =
                                        configuration?.countries.find(
                                          (country) =>
                                            country?.country_id === value?.value
                                        )?.emirates ?? [];
                                      setEmirateOptions(emirates);
                                      field.onChange(value);
                                      setValue("emirate", null);
                                    }}
                                    noOptionsMessage={() => t("No options")}
                                  />
                                )}
                              />
                              {errors.country && (
                                <FieldValidationText
                                  errorMessage={errors.country?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={6} xs={12} className="mb-3">
                            <Form.Group
                              controlId="emirate"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel
                                text={
                                  <>
                                    {t("Region")}
                                    <span className="text-danger">*</span>
                                  </>
                                }
                              />
                              <Controller
                                name="emirate"
                                control={control}
                                rules={{ required: t("Region is required") }}
                                render={({ field }) => (
                                  <ReactSelect
                                    {...field}
                                    options={emirateOptions
                                      ?.map((city) => ({
                                        value: city?.emirate_id,
                                        label: lang?.startsWith("ar")
                                          ? city?.emirate_name_ar
                                          : city?.emirate_name_en,
                                      }))
                                      ?.sort((a, b) =>
                                        a.label.localeCompare(b.label)
                                      )}
                                    placeholder={t("Select Region")}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    noOptionsMessage={() => t("No options")}
                                  />
                                )}
                              />
                              {errors.emirate && (
                                <FieldValidationText
                                  errorMessage={errors.emirate?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={6} xs={12} className="mb-3">
                            <Form.Group
                              controlId="website"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel text={t("Website")} />
                              <InputField
                                className="text-en-start"
                                type="url"
                                placeholder=""
                                {...register("websiteURL")}
                                onChange={(e) =>
                                  handleChange(e, GENERAL_URL_REGEX)
                                }
                              />
                              {errors.websiteURL && (
                                <FieldValidationText
                                  errorMessage={errors.websiteURL?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={6} xs={12} className="mb-3">
                            <Form.Group
                              controlId="facebook"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel text={t("Facebook")} />
                              <InputField
                                className="text-en-start"
                                type="url"
                                placeholder=""
                                {...register("facebookURL")}
                                onChange={(e) =>
                                  handleChange(e, GENERAL_URL_REGEX)
                                }
                              />
                              {errors.facebookURL && (
                                <FieldValidationText
                                  errorMessage={errors.facebookURL?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={6} xs={12} className="mb-3">
                            <Form.Group
                              controlId="xField"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel text="X" />
                              <InputField
                                className="text-en-start"
                                type="url"
                                placeholder=""
                                {...register("xURL")}
                                onChange={(e) =>
                                  handleChange(e, GENERAL_URL_REGEX)
                                }
                              />
                              {errors.xURL && (
                                <FieldValidationText
                                  errorMessage={errors.xURL?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={6} xs={12} className="mb-3">
                            <Form.Group
                              controlId="instagram"
                              className="mb-0 mb-md-3"
                            >
                              <InputLabel text={t("Instagram")} />
                              <InputField
                                className="text-en-start"
                                type="url"
                                placeholder=""
                                {...register("instagramURL")}
                                onChange={(e) =>
                                  handleChange(e, GENERAL_URL_REGEX)
                                }
                              />
                              {errors.instagramURL && (
                                <FieldValidationText
                                  errorMessage={errors.instagramURL?.message}
                                />
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="1">
                      <div className="contentBox">
                        <div className="titleHead">
                          <h2 className="font-bukra fw-700 text-black m-0">
                            {t("Upload Documents")}
                          </h2>
                        </div>
                        <p>{t("requiredDocumentText")} </p>
                        <Row>
                          <Col md={4} xs={12} className="mb-3">
                            <Controller
                              name="image"
                              control={control}
                              rules={{ required: t("Logo is required") }}
                              render={({ field }) => (
                                <FileUpload
                                  title={`${t("Upload Logo")}`}
                                  onFileChange={(value) => {
                                    setValue("image", value);
                                  }}
                                  acceptedFileType={{
                                    "image/png": [".png"],
                                    "image/jpg": [".jpg"],
                                    "image/jpeg": [".jpeg"],
                                  }}
                                />
                              )}
                            />
                            {errors.image && (
                              <FieldValidationText
                                errorMessage={errors.image?.message}
                              />
                            )}
                          </Col>
                          <Col md={4} xs={12} className="mb-3">
                            <Controller
                              name="license"
                              control={control}
                              rules={{ required: t("License is required") }}
                              render={({ field }) => (
                                <FileUpload
                                  title={`${t("License")}`}
                                  onFileChange={(value) =>
                                    setValue("license", value)
                                  }
                                  type="pdf"
                                  acceptedFileType={{
                                    "application/pdf": [".pdf"],
                                    "image/png": [".png"],
                                    "image/jpg": [".jpg"],
                                    "image/jpeg": [".jpeg"],
                                  }}
                                />
                              )}
                            />
                            {errors.license && (
                              <FieldValidationText
                                errorMessage={errors.license?.message}
                              />
                            )}
                          </Col>
                          <Col md={4} xs={12} className="mb-3">
                            <Controller
                              name="passport"
                              control={control}
                              rules={{ required: t("Passport is required") }}
                              render={({ field }) => (
                                <FileUpload
                                  title={`${t("Owner Passport and ID")}`}
                                  onFileChange={(value) =>
                                    setValue("passport", value)
                                  }
                                  type="pdf"
                                  acceptedFileType={{
                                    "application/pdf": [".pdf"],
                                    "image/png": [".png"],
                                    "image/jpg": [".jpg"],
                                    "image/jpeg": [".jpeg"],
                                  }}
                                />
                              )}
                            />
                            {errors.passport && (
                              <FieldValidationText
                                errorMessage={errors.passport?.message}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <ListGroup
                              as="ol"
                              numbered
                              className="list-unstyle list-number"
                            >
                              <ListGroup.Item as="li">
                                {t("validLicenseText")}.
                              </ListGroup.Item>
                              <ListGroup.Item as="li">
                                {t("offerdurationText")}.
                              </ListGroup.Item>
                              <ListGroup.Item as="li">
                                {t(
                                  "Discount percentage to be minimum 30%, variable to change according to company activities",
                                  { percentage: "30" }
                                )}
                                .
                              </ListGroup.Item>
                              <ListGroup.Item as="li">
                                {t("attachLogoText")}.
                              </ListGroup.Item>
                              <ListGroup.Item as="li">
                                {t("offerAvailabilityText")}.
                              </ListGroup.Item>
                              <ListGroup.Item as="li">
                                {t("appEnglishArabicText")}.
                              </ListGroup.Item>
                              <ListGroup.Item as="li">
                                {t("englishAbrabicInfo")}.
                              </ListGroup.Item>
                              <ListGroup.Item as="li">
                                {t("companyCondition")}.
                              </ListGroup.Item>
                            </ListGroup>
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                      <div className="contentBox">
                        <div className="titleHead d-flex align-items-center justify-content-between">
                          <h2 className="font-bukra fw-700 text-black m-0">
                            {t("Do you have branches?")}
                          </h2>
                          <div className="d-flex align-items-center">
                            {watch("hasBranches") && branches.length > 0 && (
                              <Button
                                variant="outline"
                                className="btn-md me-3 pointer"
                                onClick={handleShow}
                              >
                                {t("Add another branch")}
                              </Button>
                            )}
                            <Controller
                              name="hasBranches"
                              control={control}
                              render={({ field }) => (
                                <Form.Check
                                  type="switch"
                                  {...field}
                                  onChange={async (e) => {
                                    field.onChange(e.target.checked);
                                    if (!e.target.checked) {
                                      clearErrors("branches");
                                    }
                                  }}
                                  value={field.value}
                                />
                              )}
                            />
                          </div>
                        </div>
                        {!watch("hasBranches") && (
                          <p>
                            {t(
                              "You can simply toggle on the button to add branches."
                            )}
                          </p>
                        )}
                        {watch("hasBranches") && branches?.length === 0 && (
                          <>
                            <p>
                              {t(
                                "Please provide details about one of your outlets, or unslecet the outlet option to proceed and compelte the registration."
                              )}
                            </p>
                            <div className="brhTab">
                              <span
                                onClick={handleShow}
                                className="title d-inline-flex align-items-center"
                              >
                                <span className="me-2">
                                  <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.16406 5.16675V0.166748H6.83073V5.16675H11.8307V6.83341H6.83073V11.8334H5.16406V6.83341H0.164062V5.16675H5.16406Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span>
                                {t("Add Branch")}
                              </span>
                            </div>
                          </>
                        )}
                        {watch("hasBranches") && branches.length > 0 && (
                          <>
                            <div className="branches mt-4">
                              <p>
                                {branches.length} {t("branch added")}
                              </p>
                              {branches?.map((branch, index) => (
                                <BranchTab
                                  onEditClick={(value) => {
                                    setBranchState(value);
                                    setBranch(true);
                                  }}
                                  key={branch?.id}
                                  branch={branch}
                                  onDelete={removeBranch}
                                  index={index}
                                />
                              ))}
                            </div>
                          </>
                        )}
                        {errors?.branches?.root && (
                          <FieldValidationText
                            errorMessage={errors?.branches?.root?.message}
                          />
                        )}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
              <Row className="mt-3 mt-md-4">
                <Col xs={12}>
                  <div className="d-flex justify-content-end gap-3">
                    {activeTab > 0 && (
                      <Button
                        variant="outline"
                        className="btn-md"
                        onClick={handelBackTab}
                      >
                        {t("Back")}
                      </Button>
                    )}
                    <Button
                      variant="outline"
                      className="btn-md btn-outline-secondary"
                      onClick={handleCancel}
                    >
                      {t("Cancel")}
                    </Button>
                    {activeTab == 2 ? (
                      <ButtonWithLoading
                        onClick={async (e) => {
                          e.preventDefault();
                          await trigger("branches");
                          handleSubmit(onSubmit)();
                        }}
                        variant="primary"
                        text={t("Submit")}
                        isLoading={isLoading}
                        className="btn-md"
                      />
                    ) : (
                      <Button
                        variant="primary"
                        className="btn-md ms-3"
                        onClick={handelNextTab}
                        type="button"
                      >
                        {t("Continue")}
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </Tab.Container>
      </div>
      <Modal
        show={branch}
        onHide={handleClose}
        className="accountModal branch scrollView"
        centered
        scrollable
      >
        <Modal.Header>
          <Modal.Title className="font-bukra fw-500">
            {t("Create an Outlet")}
          </Modal.Title>
        </Modal.Header>
        <Button
          className="closeBtn primary-color text-decoration-none fw-700"
          variant="link"
          onClick={handleClose}
        >
          {t("Close")}
        </Button>
        <Modal.Body>
          <BranchForm
            defaultValues={branchState}
            handelBranchAdd={handelBranchAdd}
            handleClose={handleClose}
            branches={watch("branches") ?? []}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        onHide={handleSuccessClose}
        className="accountModal successModal"
        centered
      >
        <Button
          className="closeBtn primary-color text-decoration-none fw-700"
          variant="link"
          onClick={handleSuccessClose}
        >
          {t("Close")}
        </Button>
        <Modal.Body className="">
          <div
            style={{ width: "80px", height: "80px" }}
            className="circle-img rounded-circle bg-primary d-inline-flex align-items-center justify-content-center mb-4 mt-md-3"
          >
            <svg
              width="24"
              height="18"
              viewBox="0 0 24 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: "50px", height: "auto" }}
            >
              <path
                d="M7.74919 17.6626C7.06793 17.6629 6.41457 17.3921 5.93325 16.91L0.443061 11.4219C-0.147687 10.8309 -0.147687 9.87302 0.443061 9.28209C1.034 8.69134 1.99191 8.69134 2.58284 9.28209L7.74919 14.4484L21.4172 0.780463C22.0081 0.189715 22.966 0.189715 23.5569 0.780463C24.1477 1.3714 24.1477 2.32931 23.5569 2.92024L9.56513 16.91C9.08381 17.3921 8.43045 17.6629 7.74919 17.6626Z"
                fill="white"
              />
            </svg>
          </div>
          <h4 className="font-bukra fw-500 text-black">{t("Thank you!")}</h4>
          <p className="mb-4 px-md-5 mx-auto">
            {t(
              "We’ve received your company registeration request, our team will be in touch for next steps"
            )}
          </p>
          <Row>
            <Col md={12} xs={12}>
              <Button
                variant="primary"
                className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                onClick={handleSuccessClose}
              >
                {t("Done")}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RegisterCompany;
