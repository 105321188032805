import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputLabel from "../../Components/Form/InputLabel";
import OtpInput from "react-otp-input";
import Alert from "react-bootstrap/Alert";
import CustomButton from "../../Components/Global/customButton";
import { Controller } from "react-hook-form";
import useApi from "../../hooks/useApi";
import { AuthAPI } from "../../apis/authApi";
import FieldValidationText from "../../Components/Global/fieldValidationText";
import { useTranslation } from "react-i18next";
import useForm from "../../hooks/useForm";
import { Image } from "react-bootstrap";
import { selectLangState } from "../../redux/configurationSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleToken } from "../../redux/authSlice";
import { emiratesIdForDB } from "../../utils/utilityFunctions";
import ButtonWithLoading from "../buttons/ButtonWithLoading";
import { toast } from "react-toastify";
import OtpResponseModal from "../Modal/OtpResponseModal";

const OtpVerification = ({ setCurrent, state, setState, type }) => {
  const { lang } = useSelector(selectLangState);
  const [show, setShow] = useState(false);
  const [modalState, setModalState] = useState({
    status: "",
    title: "",
    description: "",
    btnText: "",
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { mobile_no, email, emiratesId } = state;
  const [timer, setTimer] = useState(40);
  const [isResending, setIsResending] = useState(false);
  const [loading, invokeApi] = useApi();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setError,
    trigger,
    setValue,
    formState: { errors, isSubmitting, isDirty, isValid },
    handleChange,
  } = useForm({
    mode: "all",
  });

  const close = () => {
    setShow(false);
  };

  const handleResponse = (response) => {
    if (response?.status === "WRONG_ATTEMPT") {
      toast.error(response?.title);
    } else {
      setModalState({
        status: response?.status,
        title: response?.title,
        description: response?.desc,
        btnText: response?.cta_title ?? "",
      });
      setShow(true);
    }
  };

  const onSubmit = async (values) => {
    try {
      const data = {
        otp_type: type,
        company: "ESD",
        platform: "website",
        language: lang?.startsWith("ar") ? "ar" : "en",
        otp: values.otp,
      };
      let res = null;
      if (emiratesId) {
        data.emirates_id = emiratesIdForDB(emiratesId);
        res = await invokeApi(() => AuthAPI.activateMembership(data));
      } else {
        if (mobile_no) {
          data.mobile_no = mobile_no;
        } else if (email) {
          data.email = email;
        }
        res = await invokeApi(() => AuthAPI.verifyOtp(data));
      }
      if (res?.otp_attempts_dialog?.status) {
        handleResponse(res?.otp_attempts_dialog);
      } else {
        dispatch(handleToken(res?.access_token));
        if (type === "REGISTRATION" && state?.status == "NEW_REGISTRATION") {
          setCurrent(2);
        } else {
          setCurrent(1);
          setState({
            mobile_no: null,
            email: null,
          });
          navigate("/setup-password");
        }
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    let intervalId;

    const startTimer = () => {
      intervalId = setInterval(() => {
        if (timer > 0) {
          setTimer((prevTimer) => prevTimer - 1);
        } else {
          clearInterval(intervalId);
        }
      }, 1000);
    };

    startTimer();

    return () => clearInterval(intervalId);
  }, [timer]);

  const handleResendClick = async () => {
    if (!isResending) {
      setIsResending(true);
      try {
        const payload = {
          otp_type: type,
          company: "ESD",
          platform: "website",
          language: lang?.startsWith("ar") ? "ar" : "en",
        };
        if (emiratesId) {
          payload.emirates_id = emiratesIdForDB(emiratesId);
          await invokeApi(() => AuthAPI.activateMembership(payload));
        } else {
          if (mobile_no) {
            payload.mobile_no = mobile_no;
          } else if (email) {
            payload.email = email;
          }
          await invokeApi(() => AuthAPI.sendOtp(payload));
        }
        setTimer(40);
        setValue("otp", null);
        setShow(false);
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setIsResending(false);
      }
    }
  };

  return (
    <>
      <div className="bg-auth">
        <Image
          src="/images/bg-auth.png"
          alt="icon-uae-pass"
          className="d-inline-block align-middle me-2"
        />
      </div>
      <div className="auth d-flex align-items-center justify-content-center">
        <div className="center-content w-100">
          <h1 className="fw-700 text-white font-bukra">
            {t("Verify your")}
            <br /> {t("mobile number")}
            <div className="d-block fw-400 subTitle font-dubai text-grey-400 mb-4">
              {t(
                "We’ve sent you a 6 digit verification code to your mobile number"
              )}
              <span className="numberDirection">
                {`${mobile_no?.slice(0, 3)} ****** ${mobile_no?.slice(-2)}`}
              </span>
            </div>
            <span className="d-block fw-400 subTitle font-dubai text-grey-400 mb-0">
              {t("adminContactText")}.
            </span>
          </h1>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Form.Group className="mb-3">
              <InputLabel
                className="text-white"
                text={t("Please enter the 6 digit code")}
              />
              <Controller
                name="otp"
                control={control}
                rules={{
                  required: t("Otp required"),
                  minLength: {
                    value: 6,
                    message: t("Please enter the 6 digit code"),
                  },
                }}
                render={({ field }) => (
                  <OtpInput
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                    numInputs={6}
                    containerStyle={"otpWrap"}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle="form-control text-white"
                    isInputNum
                    inputType="number"
                  />
                )}
              />
              {errors?.otp && (
                <FieldValidationText errorMessage={errors.otp?.message} />
              )}
            </Form.Group>
            <ButtonWithLoading
              variant="primary"
              type="submit"
              className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
              text={t("Verify")}
              disabled={!isValid || !isDirty || isSubmitting || loading}
              isLoading={!isResending && loading}
            />
            <div className="d-flex gap-2 pt-4 fw-400 fs-16">
              <span className="text-white">{t("Code not received yet")}?</span>
              {timer > 0 ? (
                <>
                  <span className="text-grey-400 numberDirection ms-auto">
                    {timer} sec
                  </span>
                </>
              ) : (
                <span className="text-white">
                  <span
                    className="secondary-color text-decoration-none cursor-pointer"
                    onClick={handleResendClick}
                    aria-disabled={isResending}
                  >
                    {t("Send again")}
                  </span>
                </span>
              )}
            </div>
          </Form>
        </div>
      </div>
      {show && (
        <OtpResponseModal
          show={show}
          close={close}
          title={modalState?.title}
          description={modalState?.description}
          btnText={modalState?.btnText}
          sendOtp={handleResendClick}
          timer={timer}
          status={modalState?.status}
          isLoading={isResending || loading}
        />
      )}
    </>
  );
};

export default OtpVerification;
