import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { APIProvider } from "@vis.gl/react-google-maps";
import i18n from "./i18";
import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "rc-slider/assets/index.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

if (process.env.NODE_ENV !== "production") {
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000);
}

const root = ReactDOM.createRoot(document.getElementById("root"));

const Root = () => {
  const [direction, setDirection] = useState(i18n.dir());

  useEffect(() => {
    const handleLanguageChange = () => {
      setDirection(i18n.dir());
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n} defaultNS={"translation"}>
          <QueryClientProvider client={queryClient}>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_CAPTCHA_CLIENTKEY}
            >
              <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                libraries={["geometry", "maps", "marker", "places"]}
              >
                <App />
              </APIProvider>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                rtl={direction === "rtl"}
              />
            </GoogleReCaptchaProvider>
          </QueryClientProvider>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
};

root.render(<Root />);
