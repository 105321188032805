import React, { useId, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row } from "react-bootstrap";
import InputLabel from "./InputLabel";
import InputField from "./InputField";
import FieldValidationText from "../Global/fieldValidationText";
import { useSelector } from "react-redux";
import { selectLangState } from "../../redux/configurationSlice";
import { useQuery } from "@tanstack/react-query";
import { HomeAPI } from "../../apis/homeApi";
import ReactSelect from "react-select";
import {
  ARABIC_NUMERIC_SEPECIAL_REGEX,
  ENGLISH_NUMERIC_SPECIAL_REGEX,
  PHONE_REGEX,
} from "../../utils/constants";
import PhoneNumberField from "../Global/phoneInput";
import useForm from "../../hooks/useForm";
import LocationCoordinate from "../Modal/LocationCoordinate";

const BranchForm = ({
  handelBranchAdd,
  defaultValues,
  handleClose,
  branches = [],
}) => {
  const { t } = useTranslation();
  const [showMap, setShowMap] = useState(false);

  const id = useId();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors },
    handleChange,
  } = useForm({
    mode: "all",
    defaultValues: {
      ...defaultValues,
      id: defaultValues?.id ?? id,
    },
  });
  const { lang } = useSelector(selectLangState);
  const { data: configuration } = useQuery({
    queryKey: [
      "config",
      {
        company_code: "ESD",
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getConfig({ ...queryKey[1] }),
  });
  const [cityOptions, setCityOptions] = useState([]);
  const [emirateOptions, setEmirateOptions] = useState([]);
  const [mallOptions, setMallOptions] = useState([]);

  const disablePrimaryButton = () => {
    if (branches.length == 0) {
      return false;
    }
    if (watch("isEdit") && watch("isPrimary")) {
      return false;
    }
    const isPrimary = branches.some((branche) => branche?.isPrimary);
    return isPrimary;
  };

  return (
    <>
      <p className="mb-4">{t("createNewBranchText")}.</p>
      <Form onSubmit={handleSubmit(handelBranchAdd)} autoComplete="off">
        <Row>
          <Col
            md={6}
            xs={12}
            className={`mb-3 ${lang == "ar" ? "order-1" : ""}`}
          >
            <Form.Group controlId="branchName-en">
              <InputLabel
                className="mb-2"
                text={
                  <>
                    {t("Branch Name")} ({t("English")})
                    <span className="text-danger">*</span>
                  </>
                }
              />
              <InputField
                className="text-en-start"
                type="text"
                placeholder=""
                {...register("nameEn", {
                  required: t("Branch Name is required"),
                })}
                onChange={handleChange}
              />
              {errors.nameEn && (
                <FieldValidationText errorMessage={errors.nameEn?.message} />
              )}
            </Form.Group>
          </Col>
          <Col
            md={6}
            xs={12}
            className={`mb-3 ${lang == "ar" ? "order-0" : ""}`}
          >
            <Form.Group controlId="branchName-ar">
              <InputLabel
                className="mb-2"
                text={
                  <>
                    {t("Branch Name")} ({t("Arabic")})
                    <span className="text-danger">*</span>
                  </>
                }
              />
              <InputField
                className="dirArabic"
                type="text"
                placeholder=""
                {...register("nameAr", {
                  required: t("Branch Name is required"),
                })}
                onChange={handleChange}
              />
              {errors.nameAr && (
                <FieldValidationText errorMessage={errors.nameAr?.message} />
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col
            md={6}
            xs={12}
            className={`mb-3 ${lang == "ar" ? "order-1" : ""}`}
          >
            <Form.Group controlId="branchDescription-en">
              <InputLabel
                className="mb-2"
                text={
                  <>
                    {t("Branch Address")} ({t("English")})
                    <span className="text-danger">*</span>
                  </>
                }
              />
              <InputField
                className="text-en-start"
                as="textarea"
                rows={2}
                placeholder=""
                {...register("addressEn", {
                  required: t("Address is required"),
                })}
                onChange={handleChange}
              />
              {errors.addressEn && (
                <FieldValidationText errorMessage={errors.addressEn?.message} />
              )}
            </Form.Group>
          </Col>
          <Col
            md={6}
            xs={12}
            className={`mb-3 ${lang == "ar" ? "order-0" : ""}`}
          >
            <Form.Group controlId="branchDescription-ar">
              <InputLabel
                className="mb-2"
                text={
                  <>
                    {t("Branch Address")} ({t("Arabic")})
                    <span className="text-danger">*</span>
                  </>
                }
              />
              <InputField
                className="dirArabic"
                as="textarea"
                rows={2}
                placeholder=""
                {...register("addressAr", {
                  required: t("Address is required"),
                })}
                onChange={handleChange}
              />
              {errors.addressAr && (
                <FieldValidationText errorMessage={errors.addressAr?.message} />
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12} className="mb-3">
            <Form.Group controlId="Latitude">
              <InputLabel
                className="mb-2"
                text={
                  <>
                    {t("Latitude")}
                    <span className="text-danger">*</span>
                  </>
                }
              />
              <InputField
                className="text-en-start alignRight"
                type="text"
                placeholder=""
                {...register("latitude", {
                  required: t("Latitude is required"),
                  pattern: {
                    value: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
                    message: t("Latitude must be a number"),
                  },
                })}
                onClick={() => {
                  setShowMap(true);
                }}
              />
              {errors.latitude && (
                <FieldValidationText errorMessage={errors.latitude?.message} />
              )}
            </Form.Group>
          </Col>
          <Col md={6} xs={12} className="mb-3">
            <Form.Group controlId="Longitude">
              <InputLabel
                className="mb-2"
                text={
                  <>
                    {t("Longitude")}
                    <span className="text-danger">*</span>
                  </>
                }
              />
              <InputField
                className="text-en-start alignRight"
                type="text"
                placeholder=""
                {...register("longitude", {
                  required: t("Longitude is required"),
                  pattern: {
                    value: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
                    message: t("Longitude must be a number"),
                  },
                })}
                onClick={() => {
                  setShowMap(true);
                }}
              />
              {errors.longitude && (
                <FieldValidationText errorMessage={errors.longitude?.message} />
              )}
            </Form.Group>
          </Col>
          <Col md={6} xs={12} className="mb-3">
            <Form.Group controlId="country">
              <InputLabel
                className="mb-2"
                text={
                  <>
                    {t("Country")}
                    <span className="text-danger">*</span>
                  </>
                }
              />
              <Controller
                name="country"
                control={control}
                rules={{ required: t("Country is required") }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    options={
                      configuration?.countries
                        ?.map((country) => ({
                          label: country?.country_name,
                          value: country?.country_id,
                        }))
                        ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
                    }
                    placeholder={t("Select country")}
                    onChange={(e) => {
                      let country = configuration?.countries.find(
                        (country) => country?.country_id === e?.value
                      );
                      const cities = country?.cities ?? [];
                      const emirates = country?.emirates ?? [];
                      setCityOptions(cities);
                      setEmirateOptions(emirates);
                      setValue("city", null);
                      setValue("mall", null);
                      setValue("emirate", null);
                      field.onChange(e);
                    }}
                    noOptionsMessage={() => t("No options")}
                  />
                )}
              />
              {errors.country && (
                <FieldValidationText errorMessage={errors.country?.message} />
              )}
            </Form.Group>
          </Col>
          {/* <Col md={6} xs={12} className="mb-3">
            <Form.Group controlId="emirate">
              <InputLabel
                className="mb-2"
                text={
                  <>
                    {t("Emirate")}
                    <span className="text-danger">*</span>
                  </>
                }
              />
              <Controller
                name="emirate"
                control={control}
                rules={{ required: t("Emirate is required") }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    options={
                      emirateOptions
                        ?.map((city) => ({
                          value: city?.emirate_id,
                          label: lang?.startsWith("ar")
                            ? city?.emirate_name_ar
                            : city?.emirate_name_en,
                        }))
                        ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
                    }
                    placeholder={t("Select Emirate")}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                      noOptionsMessage={() => t("No options")}
                  />
                )}
              />
              {errors.emirate && (
                <FieldValidationText errorMessage={errors.emirate?.message} />
              )}
            </Form.Group>
          </Col> */}
          <Col md={6} xs={12} className="mb-3">
            <Form.Group controlId="city">
              <InputLabel
                className="mb-2"
                text={
                  <>
                    {t("City")}
                    <span className="text-danger">*</span>
                  </>
                }
              />
              <Controller
                name="city"
                control={control}
                rules={{ required: t("City is required") }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    options={
                      cityOptions
                        ?.map((city) => ({
                          value: city?.city_id,
                          label: city?.city_name,
                        }))
                        ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
                    }
                    placeholder={t("Select City")}
                    onChange={(e) => {
                      let city = cityOptions?.find(
                        (city) => city?.city_id === e?.value
                      );
                      const malls = city?.malls ?? [];
                      setMallOptions(malls);
                      setValue("mall", null);
                      field.onChange(e);
                    }}
                    noOptionsMessage={() => t("No options")}
                  />
                )}
              />
              {errors.city && (
                <FieldValidationText errorMessage={errors.city?.message} />
              )}
            </Form.Group>
          </Col>
          <Col md={6} xs={12} className="mb-3">
            <Form.Group controlId="mall">
              <InputLabel
                className="mb-2"
                text={
                  <>
                    {t("Mall")}
                    {/* <span className="text-danger">*</span> */}
                  </>
                }
              />
              <Controller
                name="mall"
                control={control}
                // rules={{ required: t("Mall is required") }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    options={
                      mallOptions
                        ?.map((mall) => ({
                          value: mall?.mall_id,
                          label: lang?.startsWith("ar")
                            ? mall?.mall_name_ar
                            : mall?.mall_name_en,
                        }))
                        ?.sort((a, b) => a.label.localeCompare(b.label)) ?? []
                    }
                    placeholder={t("Select mall")}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    noOptionsMessage={() => t("No options")}
                  />
                )}
              />
              {errors.mall && (
                <FieldValidationText errorMessage={errors.mall?.message} />
              )}
            </Form.Group>
          </Col>
          <Col md={6} xs={12} className="mb-3">
            <Form.Group controlId="Landline">
              <InputLabel text={t("Landline")} className="mb-2" />
              <Controller
                name="landlineNumber"
                control={control}
                // rules={{
                //   validate: (value) => {
                //     if (value && !value.match(PHONE_REGEX))
                //       return t("Please enter a valid number");
                //     return null;
                //   },
                // }}
                render={({ field }) => (
                  <PhoneNumberField field={field} trigger={trigger} />
                )}
              />
              {errors.landlineNumber && (
                <FieldValidationText
                  errorMessage={errors.landlineNumber?.message}
                />
              )}
            </Form.Group>
          </Col>
          <Col md={6} xs={12} className="mb-3">
            <Form.Group controlId="Mobile">
              <InputLabel text={t("Mobile Number")} className="mb-2" />
              <Controller
                name="mobile"
                control={control}
                // rules={{
                //   validate: (value) => {
                //     if (value && !value.match(PHONE_REGEX))
                //       return t("Please enter a valid number");
                //     return null;
                //   },
                // }}
                render={({ field }) => (
                  <PhoneNumberField field={field} trigger={trigger} />
                )}
              />
              {errors.mobile && (
                <FieldValidationText errorMessage={errors.mobile?.message} />
              )}
            </Form.Group>
          </Col>
          <Col xs={12} className="my-3">
            <h1
              className="text-black m-0"
              style={{ fontSize: "20px", fontWeight: "700" }}
            >
              {t("Outlet Contact Details")}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col
            md={6}
            xs={12}
            className={`mb-3 ${lang == "ar" ? "order-1" : ""}`}
          >
            <Form.Group controlId="manager-name">
              <InputLabel
                text={`${t("Outlet Manager Name")} (${t("English")})`}
                className="mb-2"
              />
              <InputField
                className="text-en-start"
                type="text"
                placeholder=""
                {...register("managerNameEn")}
                onChange={handleChange}
              />
              {errors.managerNameEn && (
                <FieldValidationText
                  errorMessage={errors.managerNameEn?.message}
                />
              )}
            </Form.Group>
          </Col>
          <Col
            md={6}
            xs={12}
            className={`mb-3 ${lang == "ar" ? "order-0" : ""}`}
          >
            <Form.Group controlId="manager-name">
              <InputLabel
                text={`${t("Outlet Manager Name")} (${t("Arabic")})`}
                className="mb-2"
              />
              <InputField
                className=""
                type="text"
                placeholder=""
                {...register("managerNameAr")}
                onChange={handleChange}
              />
              {errors.managerNameAr && (
                <FieldValidationText
                  errorMessage={errors.managerNameAr?.message}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12} className="mb-3">
            <Form.Group controlId="manager-email">
              <InputLabel text={t("Outlet Manager Email")} className="mb-2" />
              <InputField
                className="text-en-start alignRight"
                type="email"
                placeholder=""
                {...register("managerEmail")}
                onChange={handleChange}
              />
              {errors.managerEmail && (
                <FieldValidationText
                  errorMessage={errors.managerEmail?.message}
                />
              )}
            </Form.Group>
          </Col>
          <Col md={6} xs={12} className="mb-3">
            <Form.Group controlId="Landline">
              <InputLabel text={t("Outlet Contact Number")} className="mb-2" />
              <Controller
                name="managerMobile"
                control={control}
                // rules={{
                //   validate: (value) => {
                //     if (value && !value.match(PHONE_REGEX))
                //       return t("Please enter a valid number");
                //     return null;
                //   },
                // }}
                render={({ field }) => (
                  <PhoneNumberField field={field} trigger={trigger} />
                )}
              />
              {errors.managerMobile && (
                <FieldValidationText
                  errorMessage={errors.managerMobile?.message}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="align-items-center d-flex justify-content-end">
          {!disablePrimaryButton() && (
            <Col md={6} xs={12}>
              <div className="d-flex align-items-center toggle-primaryBranch">
                <Controller
                  name="isPrimary"
                  control={control}
                  render={({ field }) => (
                    <Form.Check
                      type="switch"
                      {...field}
                      onChange={async (e) => {
                        field.onChange(e.target.checked);
                      }}
                      value={field.value}
                      checked={field.value}
                    />
                  )}
                />

                <span className="ms-2 text-black fw-700 pt-1">
                  {t("Primary Branch?")}
                </span>
              </div>
            </Col>
          )}
          <Col md={6} xs={12}>
            <div className="buttons d-flex justify-content-between justify-content-md-end pt-3 pt-md-0">
              <Button
                variant="outline"
                className="btn-md"
                aria-label="Cancel Button"
                onClick={handleClose}
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="primary"
                className="btn-md ms-3"
                type="submit"
                aria-label="Add Branch Button"
              >
                {t("Add")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <LocationCoordinate
        show={showMap}
        close={() => setShowMap(false)}
        setValue={setValue}
      />
    </>
  );
};

export default BranchForm;
