export const news = {
  total_news: 15,
  news: [],
};

export const datePickrDateFormat = "d-m-Y";

export const featuredBrands = [
  {
    id: 1,
    image: "/images/fp-1.png",
    name: "",
  },
  {
    id: 2,
    image: "/images/fp-2.png",
    name: "",
  },
  {
    id: 3,
    image: "/images/fp-3.png",
    name: "",
  },
  {
    id: 4,
    image: "/images/fp-4.png",
    name: "",
  },
  {
    id: 5,
    image: "/images/fp-5.png",
    name: "",
  },
  {
    id: 6,
    image: "/images/fp-6.png",
    name: "",
  },
  {
    id: 7,
    image: "/images/fp-7.png",
    name: "",
  },
  {
    id: 8,
    image: "/images/fp-8.png",
    name: "",
  },
  {
    id: 9,
    image: "/images/fp-9.png",
    name: "",
  },
  {
    id: 10,
    image: "/images/fp-10.png",
    name: "",
  },
  {
    id: 11,
    image: "/images/fp-11.png",
    name: "",
  },
  {
    id: 12,
    image: "/images/fp-12.png",
    name: "",
  },
  {
    id: 13,
    image: "/images/fp-13.png",
    name: "",
  },
  {
    id: 14,
    image: "/images/fp-14.png",
    name: "",
  },
  {
    id: 15,
    image: "/images/fp-15.png",
    name: "",
  },
  {
    id: 16,
    image: "/images/fp-16.png",
    name: "",
  },
  {
    id: 17,
    image: "/images/fp-17.png",
    name: "",
  },
  {
    id: 18,
    image: "/images/fp-18.png",
    name: "",
  },
  {
    id: 19,
    image: "/images/fp-19.png",
    name: "",
  },
  {
    id: 20,
    image: "/images/fp-20.png",
    name: "",
  },
];

export const FormField = {
  firstForm: [
    "companyNameEn",
    "companyNameAr",
    "descriptionEn",
    "descriptionAr",
    "addressEn",
    "addressAr",
    "ownerNameEn",
    "ownerNameAr",
    "email",
    "mobile",
    "category",
    "country",
    "emirate",
    "websiteURL",
    "facebookURL",
    "instagramURL",
    "xURL",
  ],
  secondForm: ["image", "license", "passport"],
  thirdForm: [
    "companyNameEn",
    "companyNameAr",
    "descriptionEn",
    "descriptionAr",
    "addressEn",
    "addressAr",
    "ownerNameEn",
    "ownerNameAr",
    "email",
    "mobile",
    "landlineNumber",
    "category",
    "country",
    "emirate",
    "websiteURL",
    "facebookURL",
    "instagramURL",
    "xURL",
    "image",
    "license",
    "passport",
    "hasBranches",
    "branches",
  ],
};

export const MAX_FILE_SIZE = 2000000;

export const TEXT_LIMITS = {
  TEXTBOX: 100,
  TEXTAREA: 1000,
  MOBILE: 16,
  NUMBER: 10,
};
export const EMIRATES_REGEX = /^([a-zA-Z0-9]{3})-\d{4}-\d{7}-\d$/;
// export const PHONE_REGEX = /^\+971\s\d{2}\s\d{3}\s\d{4}$/;
export const PHONE_REGEX_WITHOUT_SPACES = /^\+971\d{2}\d{3}\d{4}$/;
export const EMAIL_REGEX = /^[\w.+-]+(?:\.[\w+-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,}$/;

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_+=])[A-Za-z\d!@#$%^&*()-_+=]{8,}$/g;
export const PHONE_REGEX = /^(?:\+971|00971|971)\s\d{2}\s\d{3}\s\d{4}$/;

// Allow English letters, numbers, spaces and special characters only. Does not allow other languages or unicode characters.
export const ENGLISH_NUMERIC_SPECIAL_REGEX =
  /^[A-Za-z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
/**
 * Regular expression to match Arabic text containing only numbers and special characters.
 * Used to validate text input.
 */
export const ARABIC_NUMERIC_SEPECIAL_REGEX =
  /^[؀-ۿ ٠-٩!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;

export const PROFILE_MODELS_DEFAULT_VALUES = {
  email: false,
  phone: false,
  gender: false,
  nationality: false,
  country: false,
  dob: false,
  location: false,
  image: false,
  signout: false,
  removeDevice: false,
};

export const REVERSED_LANGUAGES = ["ar"];

export const Moengage = {
  EventName: {
    LOGIN_SUCCESS: "login_success",
    LOGIN_FAILURE: "login_failure",
    REGISTRATION_SUCCESS: "registration_success",
    LOG_OUT_SUCCESS: "log_out_success",
    DELETE_ACCOUNT_REQUEST: "delete_account_request",
    PROFILE_UPDATE_SUCCESS: "profile_update_success",
    HOME_SCREEN_VIEW: "home_screen_view",
    CATEGORIES_SCREEN_VIEW: "categories_screen_view",
    OUTLET_LISTING_SCREEN_VIEW: "outlet_listing_screen_view",
    MERCHANT_DETAIL_SCREEN_VIEW: "merchant_detail_screen_view",
    FAVOURITES_SCREEN_VIEW: "favourites_screen_view",
    HOME_SCREEN_TILE_CLICK: "home_screen_tile_click",
    TILE_CLICK: "tile_click",
    MENU_HOME_CLICK: "menu_home_click",
    MENU_ACCOUNT_CLICK: "menu_account_click",
    SEARCH_CLICK: "search_click",
    MERCHANT_CLICK: "merchant_click",
    MERCHANT_DETAIL_VIEW: "merchant_detail_view",
    MERCHANT_PIN_ENTER: "merchant_pin_enter",
    MERCHANT_DETAIL_OFFER_CLICK: "merchant_detail_offer_click",
    OFFER_REDEMPTION_SUCCESS: "offer_redemption_success",
    OFFER_REDEMPTION_FAILURE: "offer_redemption_failure",
    ADD_TO_FAVOURITE: "add_to_favourite",
    REMOVE_FROM_FAVOURITE: "remove_from_favourite",
    USER_PROFILE_DETAILS: "user_profile_details",
  },

  ScreenName: {
    HOME: "Home",
    CATEGORIES: "Categories",
    OUTLET_LISTING: "Outlet Listing",
    MERCHANT_DETAIL: "Merchant Detail",
    FAVOURITES: "Favourites",
  },
};
export const GENERAL_SPECIAL_REGEX = /[!#$%^*()+=\[\]{};':"\\|<>\/?]/g;
export const GENERAL_URL_REGEX = /[!@#$%^*()\[\]{};'"\\|<>?]/g;
export const GENERAL_DISALLOWED_REGEX = /[^a-zA-Z0-9@_&\-,. :+\u0600-\u06FF]/g;
export const PASSWORD_DISALLOWED_REGEX = /[^a-zA-Z0-9@_&\-,. :$!]/g;
