import React from "react";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DropZone from "../../Form/DropZone";
import { useDispatch, useSelector } from "react-redux";
import { handleUSer, selectUserDetails } from "../../../redux/authSlice";
import { toast } from "react-toastify";
import { AuthAPI } from "../../../apis/authApi";
import { useMutation } from "@tanstack/react-query";
import { base64toBlob } from "../../../utils/utilityFunctions";
import ButtonWithLoading from "../../buttons/ButtonWithLoading";
import { MoengageService } from "../../../services/moengage";
import { selectLangState } from "../../../redux/configurationSlice";

const ImageModal = ({ show, close, ...rest }) => {
  const user = useSelector(selectUserDetails);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = React.useState(null);
  const { lang } = useSelector(selectLangState);

  const onSuccess = (response) => {
    dispatch(
      handleUSer({ user: { ...user, profile_image: response?.image_url } })
    );
    MoengageService.userProfileDetails({
      "Account Status": response?.user_profile?.status,
      user_country_of_residence: response?.user_profile?.country_of_residence,
      user_preferred_location: response?.user_profile?.preferred_location ?? "",
      user_preferred_currency: response?.user_profile?.preferred_currency ?? "",
      user_app_language: lang?.startsWith("ar") ? "ar" : "en",
      is_email_verified: response?.user_profile?.is_email_verified,
    });
    MoengageService.profileUpdateSuccess({});

    close();
  };
  const onError = (error) => {
    toast.error(error?.message);
  };
  const {
    mutateAsync: update,
    status,
    error,
  } = useMutation({
    mutationFn: (data) => AuthAPI.updateUserProfile(data),
    onSuccess,
    onError,
  });

  const updateImage = async () => {
    try {
      if (!selectedImage) {
        toast.error(t("Please select an image"));
        return;
      }

      const formData = new FormData();
      formData.append("profile_image", selectedImage);
      formData.append("language", lang?.startsWith("ar") ? "ar" : "en");
      await update(formData);
    } catch (error) {
      toast.error("Error updating profile image");
      console.log(error);
    }
  };

  return (
    <Modal show={show} className="accountModal" centered {...rest}>
      <Modal.Body className="">
        <>
          <div className="text-center">
            <div
              className="d-inline-block rounded-circle overflow-hidden mb-2"
              style={{ width: "140px", height: "140px" }}
            >
              <Image
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : user?.profile_image
                    ? URL.createObjectURL(
                        base64toBlob(user?.profile_image, "image/jpeg")
                      )
                    : "/images/placeholder-profile.png"
                }
                alt="user image"
                fluid
                className="d-block w-100 h-100 object-fit-cover"
              />
            </div>
            <DropZone
              onFileChange={(value) => {
                setSelectedImage(value);
              }}
              acceptedFileType={{
                "image/png": [".png"],
                "image/jpg": [".jpg"],
                "image/jpeg": [".jpeg"],
              }}
              fileSize={2}
              onDropRejected={(rejectedFiles) => {
                if (rejectedFiles?.[0]?.errors?.[0]?.code == "file-too-large") {
                  toast.error(t("File size is greater than 2MB"));
                }
                if (
                  rejectedFiles?.[0]?.errors?.[0]?.code == "file-invalid-type"
                ) {
                  toast.error(t("Invalid file type"));
                }
              }}
            >
              <p className="mb-4 primary-color fw-500 cursor-pointer">
                {t("Choose Picture")}
              </p>
            </DropZone>
          </div>

          <Row>
            <Col md={6} xs={12}>
              <ButtonWithLoading
                variant="primary"
                type="submit"
                className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                text={t("Save")}
                isLoading={status == "pending"}
                onClick={updateImage}
              />
            </Col>
            <Col md={6} xs={12}>
              <Button
                variant="outline"
                className="fw-500 font-dubai d-block w-100"
                onClick={close}
              >
                {t("Cancel")}
              </Button>
            </Col>
          </Row>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
