import { format, parse } from "date-fns";
import { REVERSED_LANGUAGES, datePickrDateFormat } from "./constants";
import forge from "node-forge";
import { HomeAPI } from "../apis/homeApi";
const gateWayURL = process.env.REACT_APP_GATEWAY_URL;

export class CustomException extends Error {
  constructor(message = "", errorData = []) {
    super();
    this.message = message;
    this.errorData = errorData;
  }
}

export const appendWordIfNotExists = (originalString, wordToAppend) => {
  const wordsArray = originalString.split(" ");

  if (!wordsArray.includes(wordToAppend)) {
    return wordsArray.concat(wordToAppend).join(" ");
  }

  return originalString;
};

export const formatDateUtility = (
  inputDateString,
  dateFormat = datePickrDateFormat
) => {
  // Check if the input date string matches the first format
  const isFormat1 = /\d{2} [a-zA-Z]+ \d{4}, \d{2}:\d{2} [APMapm]+/.test(
    inputDateString
  );

  // Use the appropriate parse format based on the condition
  const parseFormat = isFormat1 ? datePickrDateFormat : dateFormat;

  // Parse the input date string
  const parsedDate = parse(inputDateString, parseFormat, new Date());

  // Check if parsing was successful
  if (!isNaN(parsedDate)) {
    // Format the parsed date using the format "dd-MM-yy"
    return format(parsedDate, dateFormat);
  } else {
    console.error("Invalid date string format");
    return null;
  }
};

// export const handlePhoneNumber = (value) => {
//   if (value) {
//     const cleanedInput = value?.replace(/[^+\d]/g, "");

//     let formattedNumber = "";
//     for (let i = 0; i < cleanedInput.length; i++) {
//       if (i === 4 || i === 6 || i === 9) {
//         formattedNumber += " ";
//       }
//       formattedNumber += cleanedInput[i];
//     }

//     return formattedNumber;
//   }
//   return null;
// };
export const handlePhoneNumber = (value) => {
  if (value) {
    // Remove all non-numeric characters except the leading +
    const cleanedInput = value.replace(/[^+\d]/g, "");

    let formattedNumber = "";
    let numberLengthforCode = 0;
    let i = 0;

    // Check for country code and set starting index for formatting
    if (cleanedInput.startsWith("+971")) {
      formattedNumber = "+971 ";
      numberLengthforCode = 5;
      i = 4;
    } else if (cleanedInput.startsWith("00971")) {
      formattedNumber = "00971 ";
      numberLengthforCode = 6;
      i = 5;
    } else if (cleanedInput.startsWith("971")) {
      formattedNumber = "971 ";
      numberLengthforCode = 4;
      i = 3;
    } else if (cleanedInput.length < 6) {
      formattedNumber = "";
    } else {
      formattedNumber = cleanedInput.slice(0, 5) + " ";
      numberLengthforCode = 6;
      i = 5;
    }

    if (formattedNumber === "") {
      return cleanedInput?.trim();
    }
    // Format the number after the country code
    for (; i < cleanedInput.length; i++) {
      if (i === numberLengthforCode + 1 || i === numberLengthforCode + 4) {
        formattedNumber += " ";
      }
      formattedNumber += cleanedInput[i];
    }

    return formattedNumber.trim();
  }
  return null;
};
export const phoneNumberForDB = (value) => {
  if (!value) return "";
  return value.replace(/\s/g, "");
};
export const formatDate = (value) => {
  const year = value.getFullYear();
  const month = String(value.getMonth() + 1).padStart(2, "0");
  const day = String(value.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
};

export const stringSliceWithdot = (str, count = 15) => {
  if (str.length > count) {
    return str.slice(0, count) + "...";
  } else {
    return str;
  }
};

export const stringWithdotinMiddle = (str) => {
  return str.slice(0, 3) + "..." + str.slice(-3);
};

export const disableAphabet = (event) => {
  // Allow: backspace, delete, tab, escape, enter, and numeric keys
  if (
    event.key === "Backspace" ||
    event.key === "Delete" ||
    event.key === "Tab" ||
    event.key === "Escape" ||
    event.key === "Enter" ||
    (event.key >= "0" && event.key <= "9")
  ) {
    return true;
  } else {
    // Prevent the input of non-numeric characters
    event.preventDefault();
    return false;
  }
};

export const emiratesIdForDB = (value) => value.replace(/-/g, "");

export const handleEmiratesId = (value) => {
  if (value) {
    const cleanedInput = value
      ?.replace(/^(\D{0,3})\D*|(\D)/g, "$1$2")
      ?.replace(/[^a-zA-Z0-9]/g, "");

    let formattedId = "";
    for (let i = 0; i < cleanedInput.length; i++) {
      if (i === 3 || i === 7 || i === 14) {
        formattedId += "-";
      }
      formattedId += cleanedInput[i];
    }

    return formattedId;
  }
  return null;
};
export const encryptData = (deviceInfo) => {
  let encryptedKey = localStorage.getItem("encryptedKey");
  if (!encryptedKey) {
    const cipher = forge.cipher.createCipher(
      "AES-ECB",
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    cipher.start();
    const result = JSON.stringify(deviceInfo);
    cipher.update(forge.util.createBuffer(result, "utf8"));
    cipher.finish();
    const encryptedBytes = cipher.output.getBytes();
    encryptedKey = forge.util.encode64(encryptedBytes);
    localStorage.setItem("encryptedKey", encryptedKey);
  }
  return encryptedKey;
};

const getBrowserFingerprint = () => {
  return (
    navigator.userAgent + "-" + window.screen.width + "x" + window.screen.height
  );
};

export const generateUniqueKey = () => {
  let uniqueKey = localStorage.getItem("uniqueKey");
  if (!uniqueKey) {
    const fingerprint = getBrowserFingerprint();
    const randomString = Math.random().toString(36).substring(2, 15);
    uniqueKey = randomString;
    localStorage.setItem("uniqueKey", uniqueKey);
  }
  return uniqueKey;
};

export const getApiKey = async (deviceInfo) => {
  let apiKey = localStorage.getItem("apiKey");
  if (!apiKey) {
    const encryptedKey = encryptData(deviceInfo);
    const formData = new FormData();
    formData.append("generatekey", encryptedKey);
    try {
      const response = await HomeAPI.getApikey(formData);
      if (!response?.apiKey) {
        throw new Error("API key not found");
      }
      apiKey = response?.apiKey;
      localStorage.setItem("apiKey", apiKey);
    } catch (error) {
      console.error("Error generating API key:", error);
      throw error;
    }
  }
  return apiKey;
};

export const getAppSecretKey = (timestamp, deviceId, apiKey) => {
  const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const concatenatedString = `${secretKey}${deviceId}:${apiKey}:${timestamp}`;
  const hash = forge.md.sha256.create();

  hash.update(concatenatedString, "utf8");
  return hash.digest().toHex();
};

export const fileToBase64 = (file) => {
  if (!file) return;
  const FR = new FileReader();
  FR.readAsDataURL(file);
  let base64String = null;
  FR.onload = () => {
    base64String = FR.result;
    return base64String;
  };
  FR.onerror = () => {
    console.log("Something went wrong");
  };
  return base64String;
};

export const base64toBlob = (base64String, mimeType = "text/plain") => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], { type: mimeType });
};

export const array = (size) => Array.from({ length: size }, (v, k) => k + 1);

export const downloadPDF = async (url) => {
  const pdfUrl = url;

  try {
    const response = await fetch(pdfUrl);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.target = "_blank";
    link.download = "offer_attachment.pdf";
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 0);
  } catch (error) {
    console.error("Error downloading the PDF:", error);
  }
};

export function isArrayEmpty(arr) {
  return Array.isArray(arr) && arr.length === 0;
}

export function isObjectEmpty(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else if (value && value.constructor === Object) {
    return Object.keys(value).length === 0;
  }
  return false;
}

export const getPastDate = (noOfYears) => {
  var d = new Date();
  return d.setFullYear(d.getFullYear() - noOfYears);
};

export const isReversedLang = (language) => {
  if (REVERSED_LANGUAGES.includes(language)) return true;
  return false;
};

export const extractCategoryName = (deeplink) => {
  const url = new URL(deeplink.replace("esaadcard://", "http://test.com/"));
  const params = new URLSearchParams(url.search);
  const categoryName = params.get("category_names");
  return categoryName;
};

export const capitalizeAndSpace = (str) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
const staticLinkMap = {
  home: "esaadcard://home",
  categories: "esaadcard://categories",
  "media-center/news": "esaadcard://allnews",
  "media-center/success-stories": "esaadcard://allstories",
  "my-account": "esaadcard://settings",
};

const isStaticPageLink = (deeplink) => {
  let newLink = null;
  for (const [key, value] of Object.entries(staticLinkMap)) {
    if (value == deeplink) {
      newLink = `/${key}`;
    }
  }

  return newLink;
};
export const parseDeepLink = (deeplink) => {
  const url = new URL(deeplink.replace("esaadcard://", "http://test.com/"));
  const params = new URLSearchParams(url.search);
  let link = "";

  if (params.get("category_names") && params.get("sub_category")) {
    link = `/categories/${params.get(
      "category_names"
    )}?sort=nearest&sub=${params.get("sub_category")}`;
  }
  if (params.get("search")) {
    link = `/search/${params.get("search")}`;
  }
  if (params.get("merchant_id")) {
    link = `/merchant-detail/${params.get("merchant_id")}`;
  }
  if (isStaticPageLink(deeplink)) {
    link = isStaticPageLink(deeplink);
  }
  return link;
};

export const deepLinkExist = (deeplink) => {
  if (
    !deeplink ||
    deeplink === "" ||
    deeplink === undefined ||
    deeplink === null ||
    deeplink === "null"
  ) {
    return false;
  }

  return true;
};
