import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InputField from "../../Form/InputField";
import OTPInput from "react-otp-input";

const LocationModal = ({ show, close, ...rest }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(1);
  const handleContinue = () => {
    setTab((prev) => prev + 1);
  };

  useEffect(() => {
    setTab(1);
  }, [show]);

  return (
    <Modal show={show} className="accountModal" centered {...rest}>
      {tab === 1 && (
        <Modal.Header>
          <Modal.Title className="font-bukra fw-500">
            {t("Edit Prefered Location")}
          </Modal.Title>
        </Modal.Header>
      )}
      <Button
        className="closeBtn primary-color text-decoration-none fw-700"
        variant="link"
        onClick={close}
      >
        {t("Close")}
      </Button>
      <Modal.Body className="">
        <>
          <p className="mb-4 ">
            {t("Please select your new nationality that you want to update.")}
          </p>
          <Form.Group className="mb-3">
            <p className=" fw-500 text-black">{t("Location")}</p>
            <InputField />
          </Form.Group>
          <Button
            variant="primary"
            className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
            onClick={handleContinue}
          >
            {t("Update")}
          </Button>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default LocationModal;
