import { Button, Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLangState } from "../../redux/configurationSlice";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { HomeAPI } from "../../apis/homeApi";
import PageLoader from "../loaders/pageLoader";
import { useState } from "react";

const FeaturedBrands = () => {
  const { lang } = useSelector(selectLangState);
  const location = useLocation();
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();
  const { data: featuredMerchants, isLoading } = useQuery({
    queryKey: [
      "featuredMerchants",
      {
        company: "ESD",
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
        partner_type: "ENTITY",
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getFeaturedMerchants({ ...queryKey[1] }),
  });

  const toggleShowAll = () => {
    setShowAll((prev) => !prev);
  };
  return (
    <section className="offer-section fBrands">
      {isLoading ? (
        <PageLoader />
      ) : (
        <Container>
          <Row className="head g-0">
            <Col xs={7}>
              <h2 className="fw-700 font-bukra text-black title-lg">
                {t("Our Partners")}
              </h2>
            </Col>
            <Col xs={5} className="d-flex justify-content-end">
              <div>
                <Button
                  className=" text-decoration-none fw-500 link btn btn-outline"
                  variant="outline"
                  onClick={toggleShowAll}
                >
                  {showAll ? t("Hide") : t("Show more")}
                </Button>
              </div>
            </Col>
            <Col xs={12} className="d-none d-md-block">
              <small className="fw-300 text-black text-info">
                {t("Our partners from government agencies({{count}})", {
                  count:
                    featuredMerchants?.entity_partners_logo_urls?.length ?? 0,
                })}
              </small>
            </Col>
          </Row>
          <Row xs={1} className="g-0">
            {showAll ? (
              featuredMerchants?.entity_partners_logo_urls?.map(
                (logo, index) => (
                  <Col
                    md={3}
                    xs={6}
                    className="d-flex align-items-center justify-content-center"
                    key={index}
                  >
                    <div className="imgHolder d-flex justify-content-center">
                      <Image
                        src={logo}
                        alt="Partner Logo"
                        className="d-block w-50"
                      />
                    </div>
                  </Col>
                )
              )
            ) : (
              <Col>
                <Swiper
                  dir={lang?.startsWith("ar") ? "rtl" : "ltr"}
                  key={lang?.startsWith("ar") ? "rtl" : "ltr"}
                  navigation={{
                    prevEl: ".swiper-brand-prev",
                    nextEl: ".swiper-brand-next",
                  }}
                  modules={[Navigation]}
                  className="brand-swiper"
                  breakpoints={{
                    320: {
                      slidesPerView: 3,
                      spaceBetween: 12,
                    },
                    768: {
                      slidesPerView: 5,
                      spaceBetween: 16,
                    },
                    1200: {
                      slidesPerView: 6,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {featuredMerchants?.entity_partners_logo_urls?.map(
                    (logo, key) => (
                      <SwiperSlide key={key}>
                        <Image
                          src={logo}
                          alt="merchant logo"
                          fluid
                          className="w-100"
                        />
                      </SwiperSlide>
                    )
                  )}
                  <div className="swiper-button-prev swiper-brand-prev"></div>
                  <div className="swiper-button-next swiper-brand-next"></div>
                </Swiper>
              </Col>
            )}
          </Row>
        </Container>
      )}
    </section>
  );
};

export default FeaturedBrands;
