import React, { useEffect, useState } from "react";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ChangePasswordModal = ({ show, close, ...rest }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(1);
  const handleContinue = () => {
    setTab((prev) => prev + 1);
  };

  useEffect(() => {
    setTab(1);
  }, [show]);

  return (
    <Modal show={show} className="accountModal" centered {...rest}>
      <Button
        className="closeBtn primary-color text-decoration-none fw-700"
        variant="link"
        onClick={close}
      >
        {t("Close")}
      </Button>
      <Modal.Body className="">
        <>
        <div className="text-center">
          <div className="circle-img d-inline-block">
            <Image
              src="/images/tick-img.png"
              alt="Trash image"
              fluid
              className="d-block w-100"
            />
          </div>
          <h4 className="font-bukra fw-500 mb-5">
            {t("Password has been Updated")}
          </h4>
          </div>

          <Row>
            <Col md={12} xs={12}>
              <Button
                variant="primary"
                className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                onClick={close}
              >
                {t("Done")}
              </Button>
            </Col>
          </Row>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
