import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { AuthAPI } from "../../../apis/authApi";
import { useDispatch, useSelector } from "react-redux";
import {
  handleLogin,
  handleUSer,
  selectUserDetails,
} from "../../../redux/authSlice";
import { toast } from "react-toastify";
import ButtonWithLoading from "../../buttons/ButtonWithLoading";
import { MoengageService } from "../../../services/moengage";
import { selectLangState } from "../../../redux/configurationSlice";
import useForm from "../../../hooks/useForm";

const GenderModel = ({ show, close, ...rest }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(1);
  const user = useSelector(selectUserDetails);
  const { lang } = useSelector(selectLangState);
  const dispatch = useDispatch();
  const handleClose = () => {
    close();
    setTab(1);
  };
  const {
    handleSubmit,
    control,
    watch,
    register,
    setValue,
    formState: { errors },
    handleChange,
  } = useForm({ mode: "all" });

  const onSuccess = (response) => {
    setTab(2);
    dispatch(handleUSer({ user: response?.user_profile }));
    MoengageService.userProfileDetails({
      "Account Status": response?.user_profile?.status,
      user_country_of_residence: response?.user_profile?.country_of_residence,
      user_preferred_location: response?.user_profile?.preferred_location ?? "",
      user_preferred_currency: response?.user_profile?.preferred_currency ?? "",
      user_app_language: lang?.startsWith("ar") ? "ar" : "en",
      is_email_verified: response?.user_profile?.is_email_verified,
    });
  };
  const onError = (error) => {
    toast.error(error?.message);
  };
  const {
    mutateAsync: update,
    status,
    error,
  } = useMutation({
    mutationFn: (data) => AuthAPI.updateUser(data),
    onSuccess,
    onError,
  });

  const onSubmit = async (data) => {
    try {
      const newUser = {
        gender: data?.gender,
        language: lang?.startsWith("ar") ? "ar" : "en",
      };
      let res = await update(newUser);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setValue("gender", user?.gender);
  }, [user]);

  return (
    <Modal show={show} className="accountModal" centered {...rest}>
      {tab === 1 && (
        <Modal.Header>
          <Modal.Title className="font-bukra fw-500">
            {t("Edit Gender")}
          </Modal.Title>
        </Modal.Header>
      )}

      <Button
        className="closeBtn primary-color text-decoration-none fw-700"
        variant="link"
        disabled={status == "pending"}
        onClick={handleClose}
      >
        {t("Close")}
      </Button>
      <Modal.Body className="">
        {tab === 1 && (
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <p className="mb-4 ">
              {t("Please select the gender that you want to update.")}
            </p>
            <ListGroup as="ul" className="resList mb-3">
              <ListGroup.Item as="li">
                <Form.Group controlId="marketing-emails">
                  <Form.Check
                    type="radio"
                    {...register("gender")}
                    value="Male"
                    checked={watch("gender") === "Male"}
                    label={
                      <>
                        <span className="fw-400 d-flex ">{t("Male")}</span>
                      </>
                    }
                    className="form-radio d-flex align-items-center"
                  />
                </Form.Group>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <Form.Group controlId="app-issue">
                  <Form.Check
                    type="radio"
                    {...register("gender")}
                    value="Female"
                    checked={watch("gender") === "Female"}
                    label={
                      <>
                        <span className="fw-400 d-flex ">{t("Female")}</span>
                      </>
                    }
                    className="form-radio d-flex align-items-center"
                  />
                </Form.Group>
              </ListGroup.Item>
              {/* <ListGroup.Item as="li">
                <Form.Group controlId="not-happy">
                  <Form.Check
                    type="radio"
                    {...register("gender")}
                    value="Transgender"
                    checked={watch("gender") === "Transgender"}
                    label={
                      <>
                        <span className="fw-400 d-flex ">
                          {t("Transgender")}
                        </span>
                      </>
                    }
                    className="form-radio d-flex align-items-center"
                  />
                </Form.Group>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <Form.Group controlId="expensive">
                  <Form.Check
                    type="radio"
                    {...register("gender")}
                    checked={watch("gender") === "Rather not to say"}
                    value="Rather not to say"
                    label={
                      <>
                        <span className="fw-400 d-flex ">
                          {t("Rather not to say")}
                        </span>
                      </>
                    }
                    className="form-radio d-flex align-items-center"
                  />
                </Form.Group>
              </ListGroup.Item> */}
            </ListGroup>
            <ButtonWithLoading
              variant="primary"
              type="submit"
              className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
              text={t("Update")}
              isLoading={status == "pending"}
            />
          </Form>
        )}
        {tab === 2 && (
          <>
            <div className="text-center">
              <div className="circle-img d-inline-block">
                <Image
                  src="/images/tick-img.png"
                  alt="Trash image"
                  fluid
                  className="d-block w-100"
                />
              </div>
              <h4 className="font-bukra fw-500 mb-5">
                {t("Gender has been Updated")}
              </h4>
            </div>

            <Row>
              <Col md={12} xs={12}>
                <Button
                  variant="primary"
                  className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                  onClick={handleClose}
                >
                  {t("Done")}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GenderModel;
