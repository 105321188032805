import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import { selectLangState } from "../../redux/configurationSlice";
import { useSelector } from "react-redux";
import { AuthAPI } from "../../apis/authApi";
import { MoengageService } from "../../services/moengage";
import { useState } from "react";

const CardFavourite = ({
  to,
  imageUrl,
  offerTitle,
  validity,
  offerType,
  discount,
  rating,
  isFavorite = false,
  tags = [],
  outlet = null,
  refetch = () => {},
}) => {
  const { lang } = useSelector(selectLangState);
  const [loading, setLoading] = useState(false);
  const [showFavorites, setShowFavorites] = useState(isFavorite);

  const removeFavorites = async () => {
    try {
      setLoading(true);
      const data = {
        company: "ESD",
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
        outlet_id: outlet?.id,
      };
      await AuthAPI.removeFavorites(data);
      MoengageService.removeFromFavourite({
        screen_name: "Favourite",
        merchant_name: outlet?.merchant_name,
      });
      setShowFavorites(false);
      refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="src-Card hrItem d-flex">
      <div className="imgHolder rounded overflow-hidden me-3">
        <Link to={to}>
          <Image
            src={imageUrl}
            alt="image"
            className="img-fluid d-block w-100 h-100 object-fit-cover"
          />
        </Link>
      </div>
      <div className="text">
        <div className="head d-flex align-items-start">
          <h3 className="font-bukra">
            <Link to={to} className="primary-color text-decoration-none">
              {offerTitle}
            </Link>
          </h3>
          <span
            className={`favIcon d-flex align-items-center justify-content-center rounded-circle ${
              showFavorites && "bg-primary"
            }`}
            onClick={removeFavorites}
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm text-white"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.8836 1.55161C15.9708 0.60137 14.6918 0.0606976 13.3517 0.0586932C12.0106 0.0601965 10.7303 0.600582 9.81608 1.551L9.50451 1.86987L9.19295 1.551C7.37896 -0.339921 4.3257 -0.448513 2.37339 1.3084C2.28688 1.38629 2.20332 1.46717 2.12291 1.551C0.198613 3.56129 0.198613 6.66809 2.12291 8.67838L9.04665 15.7502C9.28619 15.9951 9.68537 16.0056 9.93825 15.7736C9.94653 15.766 9.95458 15.7582 9.96242 15.7502L16.8836 8.67838C18.8078 6.6683 18.8078 3.56168 16.8836 1.55161ZM15.971 7.83723H15.9704L9.50451 14.4423L3.03805 7.83723C1.568 6.30119 1.568 3.92758 3.03805 2.39154C4.37304 0.990141 6.62826 0.902272 8.07518 2.19525C8.14543 2.25803 8.21302 2.3235 8.27784 2.39154L9.04665 3.1771C9.30001 3.42091 9.70906 3.42091 9.96242 3.1771L10.7312 2.39214C12.0662 0.990749 14.3214 0.90288 15.7683 2.19586C15.8386 2.25864 15.9062 2.3241 15.971 2.39214C17.4538 3.93062 17.4647 6.30856 15.971 7.83723Z"
                  fill="white"
                />
              </svg>
            )}
          </span>
        </div>
        <p>{validity}</p>
        <div className="d-flex align-items-center justify-content-between">
          {tags?.length > 0 && (
            <Stack direction="horizontal" gap={2}>
              {tags?.map((tag, key) => (
                <Badge
                  pill
                  key={key}
                  bg={tag?.text === "Featured" ? "light" : "primary"}
                  text={tag?.text === "Featured" ? "dark" : "white"}
                  className={tag?.text !== "Featured" && "dir-ltr"}
                >
                  {tag?.text}
                </Badge>
              ))}
            </Stack>
          )}
          <div className="rating d-flex align-items-center">
            <Image src="/images/Star.svg" alt="Star" className="me-1" />
            <span className="font-bukra fw-700 text-black">{rating}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFavourite;
