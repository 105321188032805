import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { selectLangState } from "../../../redux/configurationSlice";
import { useSelector } from "react-redux";
import { AuthAPI } from "../../../apis/authApi";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import ButtonWithLoading from "../../buttons/ButtonWithLoading";

const RemoveDeviceModal = ({
  show,
  close,
  selectDevice,
  devices,
  selectedDevice,
  refetchDevices,
  ...rest
}) => {
  const { lang } = useSelector(selectLangState);
  const { t } = useTranslation();

  const {
    mutateAsync: remove,
    status: status,
    error: error,
  } = useMutation({
    mutationFn: (data) => AuthAPI.removeDevice(data),
    onSuccess: (response) => {
      refetchDevices();
      close();
      toast.success(response?.message);
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  const removeDevice = async () => {
    const payload = {
      device_id: selectedDevice?.id,
      language: lang?.startsWith("ar") ? "ar" : "en",
    };
    try {
      await remove(payload);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal show={show} className="removeDeviceModal" centered {...rest}>
      <Button
        className="closeBtn primary-color text-decoration-none fw-700"
        variant="link"
        onClick={close}
      >
        {t("Close")}
      </Button>
      <Modal.Body className="">
        <>
          <div className="circle-img d-inline-block">
            <svg
              width="102"
              height="102"
              viewBox="0 0 102 102"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="51" cy="51" r="51" fill="#EEEEEE" />
              <path
                d="M67.6615 37.6667H59.3281V34.3333C59.3281 33.4493 58.9769 32.6014 58.3518 31.9763C57.7267 31.3512 56.8788 31 55.9948 31H45.9948C45.1107 31 44.2629 31.3512 43.6378 31.9763C43.0126 32.6014 42.6615 33.4493 42.6615 34.3333V37.6667H34.3281V41H37.6615V66C37.6615 67.3261 38.1882 68.5979 39.1259 69.5355C40.0636 70.4732 41.3354 71 42.6615 71H59.3281C60.6542 71 61.926 70.4732 62.8637 69.5355C63.8013 68.5979 64.3281 67.3261 64.3281 66V41H67.6615V37.6667ZM45.9948 34.3333H55.9948V37.6667H45.9948V34.3333ZM60.9948 66C60.9948 66.442 60.8192 66.866 60.5066 67.1785C60.1941 67.4911 59.7702 67.6667 59.3281 67.6667H42.6615C42.2194 67.6667 41.7955 67.4911 41.4829 67.1785C41.1704 66.866 40.9948 66.442 40.9948 66V41H60.9948V66Z"
                fill="#E73B36"
              />
              <path
                d="M49.3333 47.6667H46V61.0001H49.3333V47.6667Z"
                fill="#E73B36"
              />
              <path
                d="M55.9896 47.6667H52.6562V61.0001H55.9896V47.6667Z"
                fill="#E73B36"
              />
            </svg>
          </div>
          <h4 className="font-bukra fw-500">
            {devices?.confirmation_dialog?.title}
          </h4>
          <p className="mb-4">{devices?.confirmation_dialog?.description}?</p>
          <Row>
            <Col md={6} xs={12}>
              <Button
                variant="outline"
                className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                onClick={close}
              >
                {devices?.confirmation_dialog?.no_btn_title}
              </Button>
            </Col>
            <Col md={6} xs={12}>
              <ButtonWithLoading
                variant="primary"
                type="submit"
                className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                text={devices?.confirmation_dialog?.yes_btn_title}
                disabled={status == "pending"}
                isLoading={status == "pending"}
                onClick={removeDevice}
              />
            </Col>
          </Row>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveDeviceModal;
