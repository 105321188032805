import sanitizeHtml from "sanitize-html";
import { useForm as useReactHookForm } from "react-hook-form";
import { GENERAL_DISALLOWED_REGEX } from "../utils/constants";
const sanitizeData = (data) => {
  if (typeof data === "string") {
    return sanitizeHtml(data);
  }
  if (Array.isArray(data)) {
    return data.map(sanitizeData);
  }
  if (typeof data === "object" && data !== null) {
    // file is also an object and we don't want to sanitize it so checking for file here
    if (data.constructor === File) {
      return data;
    } else {
      const sanitizedObject = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          sanitizedObject[key] = sanitizeData(data[key]);
        }
      }
      return sanitizedObject;
    }
  }
  return data;
};

const useForm = (options) => {
  const formMethods = useReactHookForm(options);

  const handleSubmit = (onValid, onInvalid) => {
    return formMethods.handleSubmit((data) => {
      // Sanitize form data
      const sanitizedData = sanitizeData(data);
      onValid(sanitizedData);
    }, onInvalid);
  };

  const handleChange = (event, reg = GENERAL_DISALLOWED_REGEX) => {
    const { name, value } = event.target;
    const updatedValue = value.replace(reg, "");
    formMethods.setValue(name, updatedValue, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return { ...formMethods, handleChange };
};

export default useForm;
