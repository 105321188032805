import PageBanner from "../Components/Global/PageBanner";
import FaqAccordion from "../Components/Global/FaqAccordion";
import AppDownload from "../Components/Global/AppDownload";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { HomeAPI } from "../apis/homeApi";
import { selectLangState } from "../redux/configurationSlice";
import { useSelector } from "react-redux";

const FaqPage = () => {
  const { t } = useTranslation();
  const { lang } = useSelector(selectLangState);
  const { data: homeData } = useQuery({
    queryKey: [
      "home",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getHomeSections({ ...queryKey[1] }),
  });

  const faqsData = homeData?.sections?.find(
    (section) => section?.section_identifier === "faq_section"
  );

  return (
    <>
      <PageBanner
        imageUrl="/images/bg-pageBnr.png"
        title={t("Frequently asked questions")}
      />
      <div className="contentHolder add faqPage">
        <div className="faqContent p-0">
          <Container>
            <Row>
              <Col>
                <h1 className="fw-700 font-bukra text-black title-lg d-none d-xl-block">
                  {t("Frequently asked questions")}
                </h1>
                <FaqAccordion data={faqsData} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <AppDownload />
    </>
  );
};

export default FaqPage;
