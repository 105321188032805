import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { resetReduxStore } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import ButtonWithLoading from "../buttons/ButtonWithLoading";

const OtpResponseModal = ({
  show,
  close,
  title,
  description,
  btnText,
  sendOtp,
  status,
  timer,
  isLoading,
  ...rest
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClose = () => {
    close();
    if (status === "ACCOUNT_LOCKED") {
      navigate("/login");
      dispatch(resetReduxStore());
    }
  };

  return (
    <Modal
      show={show}
      className="accountModal otpResponseModal"
      centered
      {...rest}
    >
      {title && (
        <Modal.Header>
          <Modal.Title className="font-bukra fw-500">{title}</Modal.Title>
        </Modal.Header>
      )}
      <Button
        className="closeBtn primary-color text-decoration-none fw-700"
        variant="link"
        onClick={handleClose}
      >
        {t("Close")}
      </Button>
      <Modal.Body className="">
        <>
          {description && (
            <div className="text-center">
              {/* <div className="circle-img d-inline-block">
              <Image
                src="/images/tick-img.png"
                alt="Trash image"
                fluid
                className="d-block w-100"
              />
            </div> */}
              <p className="font-bukra fw-500 mb-5">{description}</p>
            </div>
          )}

          {btnText && (
            <Row>
              <Col md={12} xs={12}>
                <ButtonWithLoading
                  variant="primary"
                  type="submit"
                  className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                  text={`${btnText} ${timer > 0 ? `(${timer})` : ""}`}
                  isLoading={isLoading}
                  onClick={sendOtp}
                  disabled={timer > 0 || isLoading}
                />
              </Col>
            </Row>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
};

export default OtpResponseModal;
