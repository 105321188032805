import React, { useEffect, useState } from "react";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ButtonWithLoading from "../../buttons/ButtonWithLoading";
import { handleUSer, selectUserDetails } from "../../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLangState } from "../../../redux/configurationSlice";
import { toast } from "react-toastify";
import { AuthAPI } from "../../../apis/authApi";
import { useMutation } from "@tanstack/react-query";
import { Controller } from "react-hook-form";
import FieldValidationText from "../../Global/fieldValidationText";
import DatePicker from "../../Global/datePicker";
import { format, parse } from "date-fns";
import { MoengageService } from "../../../services/moengage";
import useForm from "../../../hooks/useForm";
const DateOfBirthModal = ({ show, close, ...rest }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(1);
  const user = useSelector(selectUserDetails);
  const { lang } = useSelector(selectLangState);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    watch,
    register,
    setValue,
    formState: { errors, isSubmitting, isDirty, isValid },
    handleChange,
  } = useForm({
    mode: "all",
  });
  const handleClose = () => {
    close();
    setTab(1);
  };
  const onSuccess = (response) => {
    setTab(2);
    dispatch(handleUSer({ user: response?.user_profile }));
    MoengageService.userProfileDetails({
      "Account Status": response?.user_profile?.status,
      user_country_of_residence: response?.user_profile?.country_of_residence,
      user_preferred_location: response?.user_profile?.preferred_location ?? "",
      user_preferred_currency: response?.user_profile?.preferred_currency ?? "",
      user_app_language: lang?.startsWith("ar") ? "ar" : "en",
      is_email_verified: response?.user_profile?.is_email_verified,
    });
  };
  const onError = (error) => {
    toast.error(error?.message);
  };
  const {
    mutateAsync: update,
    status,
    error,
  } = useMutation({
    mutationFn: (data) => AuthAPI.updateUser(data),
    onSuccess,
    onError,
  });

  const onSubmit = async (data) => {
    try {
      const dob = parse(data?.dob, "dd-MM-yyyy", new Date());
      const payload = {
        dob: format(dob, "dd MMMM yyyy"),
        language: lang?.startsWith("ar") ? "ar" : "en",
      };
      let res = await update(payload);
    } catch (error) {
      toast.error(error.message);
    }
  };

  // useEffect(() => {
  //   if (user?.dob) {
  //     const newDate = format(new Date(user?.dob), "dd-MM-yyyy");
  //     setValue("dob", newDate);
  //   }
  // }, [user]);

  return (
    <Modal show={show} className="accountModal" centered {...rest}>
      {tab === 1 && (
        <Modal.Header>
          <Modal.Title className="font-bukra fw-500">
            {t("Edit Date of Birth")}
          </Modal.Title>
        </Modal.Header>
      )}
      <Button
        className="closeBtn primary-color text-decoration-none fw-700"
        variant="link"
        onClick={handleClose}
        disabled={status === "pending"}
      >
        {t("Close")}
      </Button>
      <Modal.Body className="">
        {tab === 1 && (
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            {/* <p className="mb-4 ">
              {t("Please select your new country that you want to update.")}
            </p> */}
            <Form.Group className="mb-3">
              <p className=" fw-500 text-black">{t("Date of Birth")}</p>
              <div className="pickerWrap" style={{ maxWidth: "100%" }}>
                <span className="icon">
                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15 1C15 0.447715 14.5523 0 14 0C13.4477 0 13 0.447715 13 1V2H7V1C7 0.447715 6.55229 0 6 0C5.44772 0 5 0.447715 5 1V2H3C1.34315 2 0 3.34315 0 5V19C0 20.6569 1.34315 22 3 22H17C18.6569 22 20 20.6569 20 19V5C20 3.34315 18.6569 2 17 2H15V1ZM18 8V5C18 4.44772 17.5523 4 17 4H15V5C15 5.55228 14.5523 6 14 6C13.4477 6 13 5.55228 13 5V4H7V5C7 5.55228 6.55229 6 6 6C5.44772 6 5 5.55228 5 5V4H3C2.44772 4 2 4.44772 2 5V8H18ZM2 10H18V19C18 19.5523 17.5523 20 17 20H3C2.44772 20 2 19.5523 2 19V10Z"
                      fill="#008755"
                    />
                  </svg>
                </span>
                <Controller
                  name="dob"
                  control={control}
                  rules={{ required: t("Date of Birth is required") }}
                  render={({ field }) => (
                    <DatePicker
                      mode="single"
                      value={field.value}
                      isDisabled={status == "pending"}
                      placeholder={t("Date of birth")}
                      isForm={true}
                      maxDate="today"
                      {...field}
                    />
                  )}
                />
              </div>
              {errors.dob && (
                <div className="  mt-1">
                  <FieldValidationText errorMessage={errors.dob?.message} />
                </div>
              )}
            </Form.Group>
            <ButtonWithLoading
              variant="primary"
              type="submit"
              className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
              text={t("Update")}
              disabled={!isValid || !isDirty}
              isLoading={status == "pending"}
            />
          </Form>
        )}
        {tab === 2 && (
          <>
            <div className="text-center">
              <div className="circle-img d-inline-block">
                <Image
                  src="/images/tick-img.png"
                  alt="Trash image"
                  fluid
                  className="d-block w-100"
                />
              </div>
              <h4 className="font-bukra fw-500 mb-5">
                {t("Date of birth has been Updated")}
              </h4>
            </div>

            <Row>
              <Col md={12} xs={12}>
                <Button
                  variant="primary"
                  className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                  onClick={handleClose}
                >
                  {t("Done")}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DateOfBirthModal;
